const getEndPageRedirectUrl = ({
  redirectUrl,
  locale,
  skipUrlParams,
  encryptedParams = '',
}: {
  redirectUrl: string | null;
  locale: string;
  skipUrlParams?: boolean;
  encryptedParams?: string;
}): string | null => {
  if (!skipUrlParams && redirectUrl) {
    try {
      const urlRedirect = new URL(redirectUrl);

      urlRedirect.searchParams.set('sm', encryptedParams);
      if (!urlRedirect.searchParams.get('lang')) {
        urlRedirect.searchParams.set('lang', locale);
      }

      return urlRedirect.toString();
    } catch (e) {
      console.error('Error getting endpage redirect url', e);
      return redirectUrl;
    }
  }
  return redirectUrl;
};

export default getEndPageRedirectUrl;
