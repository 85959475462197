import { createSurveyStyles } from '@sm/webassets/SurveyTheme/context';

import { isPartialHalf, isPartialThird, isFullLeftOrRight, isLeftOrRight, isV2Theme } from '~app/helpers/themeLayout';

type StyleNames = 'progressBarContainer' | 'progressBar' | 'label';
type StyleProps = {
  isRTL: boolean;
};

const useStyles = createSurveyStyles<StyleNames, StyleProps>(theme => {
  return {
    progressBarContainer: {
      boxSizing: 'border-box',
      height: 24,
      background: isV2Theme(theme.name)
        ? theme.progressBarStyle?.backgroundColor ?? {}
        : theme.primaryAccentColor ?? {},
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      margin: 'auto',
      width: '100%',
      padding: '0 24px',
      borderRadius: 12,
      gap: '12px',
      direction: ({ isRTL }: StyleProps) => (isRTL ? 'rtl' : null),
      [`@media only screen and (min-width: ${theme.breakpoints?.sm.min})`]: {
        width: !isLeftOrRight(theme.layout) ? '75%' : '100%',
      },
      [`@media only screen and (min-width: ${theme.breakpoints?.md.min})`]: {
        width: !isLeftOrRight(theme.layout) ? '50%' : '',
      },
      [`@media only screen and (min-width: ${theme.breakpoints?.lg.min})`]: {
        width: isPartialThird(theme.layout) ? '75%' : '',
      },
      [`@media only screen and (min-width: ${theme.breakpoints?.xl.min})`]: {
        width: isPartialHalf(theme.layout) || isFullLeftOrRight(theme.layout) ? '75%' : '50%',
      },
    },
    progressBar: {
      width: '100%',
    },
    label: {
      fontFamily: theme?.questionBody?.fontFamily,
      color: isV2Theme(theme.name) ? theme.progressBarStyle?.color ?? {} : theme.secondaryAccentColor ?? {},
      fontSize: isV2Theme(theme.name) ? theme.progressBarStyle?.fontSize ?? {} : 13,
    },
  };
});

export default useStyles;
