import { getSrc } from '~app/helpers/assetLoading';
import {
  treatment1Poll,
  treatment1Contact,
  treatment1Event,
  treatment1Volunteer,
  treatment1Engage,
  treatment1Product,
  treatment2Survey,
  treatment2Meeting,
  treatment2Training,
  treatment2Remote,
  treatment2Feedback,
  treatment2Quiz,
} from '~static/images/personalization';

const WEBLINK_ID = 'weblink-email';
export default WEBLINK_ID;

// constants/utSourcePaths.ts
export const UT_SOURCE_PATHS = {
  OPTIONAL_PATH_MWEB1: 'optionalPathsMWeb1',
  OPTIONAL_PATH_MWEB2: 'optionalPathsMWeb2',
  OPTIONAL_PATH_MAPP1: 'optionalPathsMApp1',
  OPTIONAL_PATH_MAPP2: 'optionalPathsMApp2',
};

// Production base URL
const PROD_BASE_URL = 'https://www.surveymonkey.com';

// Platform-specific URLs
export const APP_LINKS = {
  android: 'https://play.google.com/store/apps/details?id=com.surveymonkey.rewards&pcampaignid=web_share',
  ios: 'https://apps.apple.com/us/app/surveymonkey-rewards/id1376880637',
};

export const UK_PATH_LINKS = {
  exploreUkCtaUrl: 'https://uk.surveymonkey.com/mp/workplace-trends-for-a-new-era/',
  ukSignupUrl: 'https://uk.surveymonkey.com/sign-up/',
};

export const US_PATH_LINKS = {
  exploreUsCtaUrl: 'https://surveymonkey.com/mp/workplace-trends-for-a-new-era/',
};

// constant paths for the user research survey
// Constants for specific paths
export const USER_RESEARCH_STANDARD_CTA = `${PROD_BASE_URL}/r/Q7D9YKY`;
export const USER_RESEARCH_STYLED_CTA = `${PROD_BASE_URL}/r/C6KN2RH`;
export const USER_RESEARCH_MOBILE_STYLE_CTA = `${PROD_BASE_URL}/r/B8S98DY`;

export const CTA_LINKS: Record<string, string> = {
  userResearchMobileStyle: USER_RESEARCH_MOBILE_STYLE_CTA,
  userResearchStandard: USER_RESEARCH_STANDARD_CTA,
  userResearchStyled: USER_RESEARCH_STYLED_CTA,
};

export const ALL_SURVEY_TEMPLATES_CTA = `${PROD_BASE_URL}/user/sign-up/?ep=%2Fcreate%2Ftemplates`;

export const SURVEY_TEMPLATES = [
  {
    heading: 'Discover our most popular survey templates, written by experts',
    viewAllCTA: 'See all templates',
    templateCTA: 'Use template',
    templates: [
      {
        title: 'Scheduling Poll Template',
        description: 'Decide what time works best for your meeting or event.',
        image: getSrc(treatment1Poll),
        ctaLink: `${PROD_BASE_URL}/user/sign-up/?ep=%2Fcreate%2Ftemplates%2F1640`,
      },
      {
        title: 'RSVP and Contact Information Template',
        description: 'Collect guest RSVPs and information for your next event.',
        image: getSrc(treatment1Contact),
        ctaLink: `${PROD_BASE_URL}/user/sign-up/?ep=%2Fcreate%2Ftemplates%2F1025`,
      },
      {
        title: 'General Event Feedback Template',
        description: 'Find out how people felt about your event to improve the next one.',
        image: getSrc(treatment1Event),
        ctaLink: `${PROD_BASE_URL}/user/sign-up/?ep=%2Fcreate%2Ftemplates%2F568`,
      },
      {
        title: 'Volunteer Feedback Template',
        description: 'Ask volunteers how it was working with you.',
        image: getSrc(treatment1Volunteer),
        ctaLink: `${PROD_BASE_URL}/user/sign-up/?ep=%2Fcreate%2Ftemplates%2F352`,
      },
      {
        title: 'Employee Engagement Template',
        description: 'Learn how employees feel about their job and how you can help.',
        image: getSrc(treatment1Engage),
        ctaLink: `${PROD_BASE_URL}/user/sign-up/?ep=%2Fcreate%2Ftemplates%2F607`,
      },
      {
        title: 'Product Review Template',
        description: 'Get product reviews or user content for eCommerce sites.',
        image: getSrc(treatment1Product),
        ctaLink: `${PROD_BASE_URL}/user/sign-up/?ep=%2Fcreate%2Ftemplates%2F1931`,
      },
    ],
  },
];

export const EMPLOYEE_FEEDBACK_TEMPLATES = [
  {
    heading: 'Understand what your employees need to perform at their best',
    viewAllCTA: 'View all templates',
    templateCTA: 'Use template',
    templates: [
      {
        title: 'Employee Engagement Template',
        description: 'Learn how employees feel about their job and how you can help.',
        image: getSrc(treatment2Survey),
        ctaLink: `${PROD_BASE_URL}/user/sign-up/?ep=%2Fcreate%2Ftemplates%2F607`,
      },
      {
        title: 'Meeting Feedback Template',
        description: 'Gather feedback from people who attended your meeting.',
        image: getSrc(treatment2Meeting),
        ctaLink: `${PROD_BASE_URL}/user/sign-up/?ep=%2Fcreate%2Ftemplates%2F1506`,
      },
      {
        title: 'Training Course Evaluation Template',
        description: 'Discover what attendees liked about your course and what needs work.',
        image: getSrc(treatment2Training),
        ctaLink: `${PROD_BASE_URL}/user/sign-up/?ep=%2Fcreate%2Ftemplates%2F1211`,
      },
      {
        title: 'Remote Work Employee Check-In Template',
        description: 'Support employees who work from home (WFH) with weekly check-ins.',
        image: getSrc(treatment2Remote),
        ctaLink: `${PROD_BASE_URL}/user/sign-up/?ep=%2Fcreate%2Ftemplates%2F1750`,
      },
      {
        title: '360-Degree Employee Evaluation Template',
        description: 'Collect feedback about employee performance from key groups.',
        image: getSrc(treatment2Feedback),
        ctaLink: `${PROD_BASE_URL}/user/sign-up/?ep=%2Fcreate%2Ftemplates%2F288`,
      },
      {
        title: 'Quiz - New Hire Training Template',
        description: 'Review what new employees learned after their first training.',
        image: getSrc(treatment2Quiz),
        ctaLink: `${PROD_BASE_URL}/user/sign-up/?ep=%2Fcreate%2Ftemplates%2F1234`,
      },
    ],
  },
];
