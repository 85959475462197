import React from 'react';
import { SurveyTitleHorizontalAlignment } from '~lib/generatedGqlTypes';
import { RichText } from '../../core/RichText';
import useSurveyStyles from './useStyles';

export type Props = {
  id?: Element['id'];
  align?: SurveyTitleHorizontalAlignment;
  showTitleText: boolean;
  text: string;
  element?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  logo?: {
    logo: React.ReactElement;
    verticalAlignment: 'BASELINE' | 'BOTTOM';
  };
} & React.HTMLAttributes<HTMLHeadElement>;

const SurveyTitleV2: React.FC<Props> = ({
  id,
  element = 'h1',
  text,
  align = 'LEFT',
  logo,
  showTitleText,
  ...otherProps
}) => {
  const { surveyTitle, surveyTitleWrapper } = useSurveyStyles({
    align,
    logoVerticalAlignment: logo?.verticalAlignment,
    showTitleText,
  });
  return (
    <div className={surveyTitleWrapper}>
      {logo?.verticalAlignment === 'BASELINE' && <div>{logo.logo}</div>}
      {showTitleText && (
        <RichText
          id={id}
          element={element}
          text={text}
          className={surveyTitle}
          data-testid="SurveyTitleV2__Title"
          {...otherProps}
        />
      )}
      {logo?.verticalAlignment === 'BOTTOM' && <div>{logo.logo}</div>}
    </div>
  );
};

export default SurveyTitleV2;
