import React, { useContext } from 'react';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';
import { L10NContext } from '@sm/intl';
import { useSurveyTheme } from '@sm/webassets/SurveyTheme/context';

import { isV2Theme, isOQAATFullBleed } from '~app/helpers/themeLayout';
import { RichText } from '../../core/RichText';
import useSurveyStyles from './useStyles';

/** require `number` if `numbered` is `true` */
type NumberProps =
  | {
      numbered?: false;
      number?: number;
    }
  | {
      numbered: true;
      number: number;
    };

export type Props = {
  id?: Element['id'];
  text: string;
  element?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  isOQAAT?: boolean;
} & NumberProps &
  React.HTMLAttributes<HTMLHeadingElement>;

export type PageTitleStyleProps = {
  themeName: string;
  isOQAAT: boolean;
};

const useStyles = createUseStyles({
  pageTitleV2Theme: {
    padding: '14px 40px',
    marginTop: -8,
    position: ({ isOQAAT, themeName }: PageTitleStyleProps) =>
      isOQAATFullBleed(themeName, isOQAAT) ? 'relative' : null,
    width: ({ isOQAAT, themeName }: PageTitleStyleProps) =>
      isOQAATFullBleed(themeName, isOQAAT) ? 'calc(100vw - 80px)' : null,
    left: ({ isOQAAT, themeName }: PageTitleStyleProps) => (isOQAATFullBleed(themeName, isOQAAT) ? '50%' : null),
    right: ({ isOQAAT, themeName }: PageTitleStyleProps) => (isOQAATFullBleed(themeName, isOQAAT) ? '50%' : null),
    marginLeft: ({ isOQAAT, themeName }: PageTitleStyleProps) => (isOQAATFullBleed(themeName, isOQAAT) ? '-50vw' : -40),
    marginRight: ({ isOQAAT, themeName }: PageTitleStyleProps) =>
      isOQAATFullBleed(themeName, isOQAAT) ? '-50vw' : -40,
  },
});

const PageTitle: React.FC<Props> = ({
  id,
  element: Element = 'h2',
  text,
  numbered = false,
  number,
  isOQAAT = false,
  ...otherProps
}) => {
  const { isRTL } = useContext(L10NContext);
  const { pageTitle, questionNumber } = useSurveyStyles({ isRTL });
  const theme = useSurveyTheme();
  const themeName = theme.name;

  const { pageTitleV2Theme } = useStyles({ themeName, isOQAAT });
  return (
    <Element
      id={id}
      data-testid="PageTitle__Title"
      className={classNames(pageTitle, isV2Theme(theme.name) ? pageTitleV2Theme : {})}
      {...otherProps}
    >
      {numbered && <span className={questionNumber}>{number}.</span>}
      <RichText element="span" text={text} />
    </Element>
  );
};

export default PageTitle;
