import { ExistingResponse } from '~app/pages/SurveyTaking/utils/types';
import { InEmailResponseSurveyData } from '~helpers/pages/types';

const mergeExistingResponses = ({
  surveyVersion,
  existingResponses,
  firstQuestionResponse,
}: {
  surveyVersion: number;
  existingResponses: ExistingResponse[];
  firstQuestionResponse?: InEmailResponseSurveyData | null;
}): ExistingResponse[] => {
  if (
    !firstQuestionResponse ||
    surveyVersion !== firstQuestionResponse.responseSurveyVersion ||
    existingResponses.some(r => r.questionId === firstQuestionResponse.response?.questionId)
  ) {
    return existingResponses;
  }

  return [...existingResponses, firstQuestionResponse.response];
};

export default mergeExistingResponses;
