import React from 'react';

import { defineMessages, t } from '@sm/intl';
import { uniqueId } from '@wds/utils';

import useStyles from './useStyles';
import { withFocusTrap } from '../../FocusTrap';

const COPY = defineMessages({
  CLOSE_MODAL: {
    id: 'core.Modal.CloseModal',
    defaultMessage: 'Close tooltip',
    description: '[Type: label][Vis: Med] - close button text',
  },
});

export type Props = {
  tooltip: string;
  closeModal: () => void;
};

const Modal: React.FC<Props> = ({ tooltip, closeModal }) => {
  const { modalOverlay, closeBtn, modal, tooltipText } = useStyles();
  const closeRef = React.useRef<HTMLButtonElement>(null);
  const tooltipId = uniqueId('tooltip-modal');

  const handleClick = (e: React.MouseEvent): void => {
    // click on button or overlay should close modal
    e.stopPropagation();
    if (e.currentTarget === e.target) {
      closeModal();
    }
  };

  React.useEffect(() => {
    // listen for ESC to close modal
    const handleKeydown = (e: KeyboardEvent): void => {
      if (e.key === 'Escape') {
        e.stopPropagation();
        closeModal();
      }
    };
    document.addEventListener('keydown', handleKeydown);
    return () => {
      document.removeEventListener('keydown', handleKeydown);
    };
  }, [closeModal]);

  React.useEffect(() => {
    closeRef.current?.focus();
  }, []);

  return (
    <div className={modalOverlay} onClick={handleClick} aria-hidden="true">
      <div className={modal} role="dialog" aria-modal="true" aria-describedby={tooltipId}>
        <button
          type="button"
          className={closeBtn}
          ref={closeRef}
          onClick={handleClick}
          aria-label={t(COPY.CLOSE_MODAL)}
        >
          &times;
        </button>
        <p id={tooltipId} className={tooltipText}>
          {tooltip}
        </p>
      </div>
    </div>
  );
};

export default withFocusTrap(Modal);
