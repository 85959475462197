import { CollectorType, EndPageRedirectType } from '~lib/generatedGqlTypes';

/** The maximum number of questions which can display on a page */
export const MAX_QUESTIONS_ON_PAGE = 105;

/** The default return values for survey submission */
export const DEFAULT_SUBMIT_RESULT = {
  encryptedCookieValue: '',
  encryptedSmParam: '',
  encryptedSMParamforQuizResults: '',
  success: false,
  userErrors: null,
  surveyPage: null,
  respondent: null,
  encryptedSmSubject: null,
};

export const REDIRECT_TYPE: { [key: string]: EndPageRedirectType } = {
  url: 'URL',
  loop: 'LOOP',
  closeWindow: 'CLOSE_WINDOW',
};

export const COLLECTOR_TYPE: { [key: string]: CollectorType } = {
  email: 'EMAIL',
  weblink: 'WEBLINK',
};
