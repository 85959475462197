import { createSurveyStyles } from '@sm/webassets/SurveyTheme/context';
import { isV2Theme } from '~app/helpers/themeLayout';
import { SurveyLogo, SurveyTitleHorizontalAlignment } from '~lib/generatedGqlTypes';

type StyleClassNames = 'logoContainer' | 'logoWrapper' | 'logoImage';
type StyleProps = Omit<SurveyLogo, 'enabled' | 'image'> & {
  isRTL: boolean;
  horizontalAlignment: SurveyTitleHorizontalAlignment | null;
};

// Logo alignment comes in as `verticalAlignment`, and the keys represent
// values available from GraphQL API (SurveyLogoAlignment).  Presently, the
// only options to choose from are: CENTER, LEFT_TOP, RIGHT_TOP.
// BASELINE, TOP, BOTTOM are used for V2 Themes. Justify can be 'center' or something else because
// survey title alignment affects logo justification also
const surveyLogoAlignment = (
  horizontalAlignment: SurveyTitleHorizontalAlignment | null = null
): Record<string, { justify: string; align: string }> => {
  const flexStart = 'flex-start';
  return {
    CENTER: { justify: 'center', align: 'center' },
    LEFT_TOP: { justify: flexStart, align: flexStart },
    RIGHT_TOP: { justify: 'flex-end', align: flexStart },
    // Here for other values when implemented
    LEFT_MIDDLE: { justify: flexStart, align: 'center' },
    RIGHT_MIDDLE: { justify: 'flex-end', align: 'center' },
    BASELINE: {
      justify: horizontalAlignment === 'CENTER' ? 'center' : flexStart,
      align: 'baseline',
    },
    BOTTOM: {
      justify: horizontalAlignment === 'CENTER' ? 'center' : flexStart,
      align: 'flex-end',
    },
    TOP: {
      justify: horizontalAlignment === 'CENTER' ? 'center' : flexStart,
      align: 'flex-start',
    },
  };
};

const isActualSize = (size: string | null): boolean => {
  // presently, `null` is returned in place of 'ACTUAL', here for any regression.
  return size === null || size === 'ACTUAL';
};

const useStyles = createSurveyStyles<StyleClassNames, StyleProps>(theme => ({
  logoContainer: {
    display: 'flex',
    flexDirection: ({ isRTL }) => (isRTL ? 'row-reverse' : 'row'),
    width: '100%',
    justifyContent: ({ verticalAlignment }) => verticalAlignment && surveyLogoAlignment()[verticalAlignment].justify,
    alignItems: ({ verticalAlignment }) => verticalAlignment && surveyLogoAlignment()[verticalAlignment].align,
    ...(isV2Theme(theme.name) && {
      justifyContent: ({ verticalAlignment, horizontalAlignment }) =>
        verticalAlignment && surveyLogoAlignment(horizontalAlignment)[verticalAlignment].justify,
      paddingBottom: ({ verticalAlignment }) => verticalAlignment === 'TOP' && 18,
      paddingTop: ({ verticalAlignment }) => verticalAlignment === 'TOP' && 10,
    }),
  },
  logoWrapper: {
    width: ({ size, width }) => (size && !isActualSize(size) ? width : 'auto'),
    height: ({ size, height }) => (size && !isActualSize(size) ? height : 'auto'),
  },
  logoImage: {
    width: '100%',
    height: '100%',
    [`@media only screen and (max-width: ${theme.breakpoints?.md.max})`]: {
      height: 'auto',
    },
  },
}));

export default useStyles;
