export { default as treatment1Poll } from './surveyTemplates/template1.webp';
export { default as treatment1Contact } from './surveyTemplates/template2.webp';
export { default as treatment1Event } from './surveyTemplates/template3.webp';
export { default as treatment1Volunteer } from './surveyTemplates/template4.webp';
export { default as treatment1Engage } from './surveyTemplates/template5.webp';
export { default as treatment1Product } from './surveyTemplates/template6.webp';
export { default as treatment2Survey } from './employeeTemplates/template1.webp';
export { default as treatment2Meeting } from './employeeTemplates/template2.webp';
export { default as treatment2Training } from './employeeTemplates/template3.webp';
export { default as treatment2Remote } from './employeeTemplates/template4.webp';
export { default as treatment2Feedback } from './employeeTemplates/template5.webp';
export { default as treatment2Quiz } from './employeeTemplates/template6.webp';
