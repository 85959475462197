/* eslint-disable no-param-reassign */
import { createSelector, createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import isEqual from 'lodash-es/isEqual';
import { t } from '@sm/intl';
import { AppDispatch, RootState } from '~app/storeV2';

import { runValidators } from '../../validation/validationManager';
import transformToSurveyErrors from '../../errors/transformToSurveyErrors';
import { ErrorType, defaultErrorGroups } from '../../errors/constants';
import { SurveyError, SurveyErrorMetaGroup, SurveyErrorType, SurveyErrorGroup } from '../../errors/types';
import { setFocusToView } from '~app/components/Survey/SurveyFormat';
import { sortByQuestionOrder } from '../../errors/helpers/sortByQuestionOrder';
import { MAX_QUESTIONS_ON_PAGE } from '../../constants';
import { initializeSurvey } from './surveySlice';
import WEBLINK_ID from '~app/components/constants';
import { COPY } from '~app/components/Survey/WeblinkConfirmationCheckbox';

// ========== INITIAL STATE

export type ErrorsState = {
  /** Error metadata */
  meta: SurveyErrorMetaGroup;
  /** Error codes grouped by category */
  groups: SurveyErrorGroup;
  /** The list of errors that currently exist in the survey */
  errors: SurveyError[];
  /**
   * Array containing the ids of each question in order, used to sort errors
   * to prevent the incorrect one from being focused on Done.
   * This is needed here because the survey state is not available to reducers in
   * this slice. It may be worth looking into including validation errors with
   * questions in future.
   */
  questionOrder: string[];
};

export const initialState: ErrorsState = {
  meta: {},
  groups: defaultErrorGroups,
  errors: [],
  questionOrder: [],
};

// ========== SLICE

const sliceName = 'errors';

export const errorsSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setMeta: (state, action: PayloadAction<SurveyErrorMetaGroup>) => {
      state.meta = action.payload;
    },
    addError: (state, action: PayloadAction<SurveyError>) => {
      state.errors = [...state.errors, action.payload].sort(sortByQuestionOrder(state.questionOrder));
    },
    setErrors: (state, { payload: errors }: PayloadAction<SurveyError[]>) => {
      state.errors = errors.sort(sortByQuestionOrder(state.questionOrder));
    },
    setErrorsById: (
      state,
      action: PayloadAction<{ id: string | (string | null)[] | null; newErrors: SurveyError[] }>
    ) => {
      const { id, newErrors } = action.payload;
      const ids = Array.isArray(id) ? id : [id];
      state.errors = [...state.errors.filter(e => !ids.includes(e.questionId)), ...newErrors].sort(
        sortByQuestionOrder(state.questionOrder)
      );
    },
    removeErrorsById: (state, { payload: id }: PayloadAction<string | (string | null)[] | null>) => {
      const ids = Array.isArray(id) ? id : [id];
      state.errors = state.errors.filter(e => !ids.includes(e.questionId));
    },
    removeErrorsByType: (state, { payload: type }: PayloadAction<SurveyErrorType | SurveyErrorType[]>) => {
      const types: SurveyErrorType[] = Array.isArray(type) ? type : [type];
      // If scalability becomes a concern for this feature in the future, we will devise an alternative solution.
      state.errors = state.errors
        .filter(e => !types.includes(e.code) || e.questionId === WEBLINK_ID)
        .sort(sortByQuestionOrder(state.questionOrder));
    },
    setQuestionOrder: (state, action: PayloadAction<string[]>) => {
      state.questionOrder = action.payload;
    },
    reset: () => initialState,
  },
  // extraReducers allow us to listen to actions from other slices and update this slice's state accordingly.
  // https://redux-toolkit.js.org/api/createslice#extrareducers
  extraReducers: {
    // update questionOrder when the survey is initialized
    // `survey/initializeSurvey` is the same as `initializeSurvey.type` from `surveySlice.ts`
    // needed to be hardcoded to break circular dependency
    'survey/initializeSurvey': (state, action: ReturnType<typeof initializeSurvey>) => {
      state.questionOrder =
        action.payload?.surveyTakingCurrentPage?.surveyPage?.surveyPageQuestions?.items.map(q => q.id) || [];
    },
  },
});

// ========== ACTIONS

export const {
  setMeta,
  addError,
  setErrors,
  setErrorsById,
  removeErrorsById,
  removeErrorsByType,
  setQuestionOrder,
  reset,
} = errorsSlice.actions;

// ========== THUNKS

/** Focus the first question which has a validation error */
export const focusFirstQuestionError = createAsyncThunk(`${sliceName}/focusFirstQuestionError`, (_, thunkAPI): void => {
  const {
    errorsState,
    surveyState: { survey },
  } = thunkAPI.getState() as RootState;

  const validationErrors = errorsState.errors.filter(e => errorsState.groups.validation.includes(e.code));

  // if present, move weblink question error to be the last error
  const weblinkErrorIndex = validationErrors.findIndex(e => e.questionId === WEBLINK_ID);
  if (weblinkErrorIndex > -1) {
    const weblinkError = validationErrors[weblinkErrorIndex];
    validationErrors.splice(weblinkErrorIndex, 1);
    validationErrors.push(weblinkError);
  }

  const firstError = validationErrors?.[0]?.questionId || null;
  if (firstError) {
    requestAnimationFrame(() => {
      setFocusToView(firstError, true, errorsState.questionOrder, survey?.format === 'CLASSIC');
    });
  }
});

/** Run error validation for all questions on the page */
export const validatePage = createAsyncThunk(`${sliceName}/validatePage`, (isPrevious: boolean, thunkAPI): boolean => {
  const { isValid, errors } = runValidators();
  const { errorsState } = thunkAPI.getState() as RootState;
  const dispatch = thunkAPI.dispatch as AppDispatch;
  const hasEmailConfirmationError = errorsState.errors.some(err => err.questionId === WEBLINK_ID);

  if (!isValid) {
    // temporary while aligning error-shape between Server and SMQ
    const questionErrors = transformToSurveyErrors(errors);
    const questionIds = questionErrors.map(a => a.questionId);
    if (!isPrevious) {
      dispatch(setErrorsById({ id: questionIds, newErrors: questionErrors }));
    }
  } else {
    dispatch(removeErrorsByType(errorsState.groups.validation));
  }

  if (hasEmailConfirmationError) {
    if (isPrevious) {
      dispatch(removeErrorsById(WEBLINK_ID));
    }
    return false;
  }
  return isValid;
});

export const validateConfirmationEmail = createAsyncThunk(
  `${sliceName}/validateConfirmationEmail`,
  ({ isChecked, emailValue }: { isChecked: boolean; emailValue: string }, thunkAPI): void => {
    const { errorsState } = thunkAPI.getState() as RootState;
    if (errorsState.errors.some(e => e.questionId === WEBLINK_ID)) {
      return;
    }

    if (isChecked && !emailValue.length) {
      const dispatch = thunkAPI.dispatch as AppDispatch;
      dispatch(
        addError({
          code: ErrorType.MISSING_REQUIRED_FIELD,
          detail: t(COPY.EMAIL_ERROR),
          questionId: WEBLINK_ID,
          field: null,
        })
      );
    }
  }
);

/** Update the question order array based on the current page in store */
export const updateQuestionOrder = createAsyncThunk(`${sliceName}/updateQuestionOrder`, (_, thunkAPI): void => {
  const { surveyState } = thunkAPI.getState() as RootState;
  const dispatch = thunkAPI.dispatch as AppDispatch;
  dispatch(setQuestionOrder(surveyState.activePage?.surveyPage?.surveyPageQuestions?.items.map(q => q.id) || []));
});

// ========== SELECTORS

/** The errors state object */
const selectErrorsState = (state: RootState): ErrorsState => state.errorsState;

/** The list of errors that currently exist in the survey */
export const selectErrors = createSelector(selectErrorsState, errorsState => errorsState.errors);

/** Error codes grouped by category */
export const selectErrorGroups = createSelector(selectErrorsState, errorsState => errorsState.groups);

/** Error metadata */
export const selectMeta = createSelector(selectErrorsState, errorsState => errorsState.meta);

export const selectHasNoErrors = createSelector(selectErrors, errors => errors.length === 0);

export const selectHasVersionChanged = createSelector(selectErrors, errors =>
  errors.some(({ code }: SurveyError) => code === ErrorType.INVALID_SURVEY_VERSION)
);

export const selectHasNotFoundErrors = createSelector([selectErrors, selectErrorGroups], (errors, groups) =>
  errors.some(({ code }: SurveyError) => groups.notFound.includes(code))
);

export const selectHasRedirectErrors = createSelector([selectErrors, selectErrorGroups], (errors, groups) =>
  errors.some(({ code }: SurveyError) => groups.redirect.includes(code))
);

export const selectHasValidationErrors = createSelector([selectErrors, selectErrorGroups], (errors, groups) =>
  errors.some(({ code }: SurveyError) => groups.validation.includes(code))
);

export const selectHasGoneErrors = createSelector([selectErrors, selectErrorGroups], (errors, groups) =>
  errors.some(({ code }: SurveyError) => groups.gone.includes(code))
);

export const selectHasCannedErrors = createSelector(selectErrors, errors =>
  errors.some(({ code }: SurveyError) => code === ErrorType.SURVEY_CANNED)
);

export const selectHasBadRequestErrors = createSelector([selectErrors, selectErrorGroups], (errors, groups) =>
  errors.some(({ code }: SurveyError) => groups.badRequest.includes(code))
);

export const selectHasUnknownErrors = createSelector([selectErrors, selectErrorGroups], (errors, groups) =>
  errors.some(({ code }: SurveyError) => !Object.values(groups).flat().includes(code))
);

/** The list of errors for the question specified by Id */
// createSelector generates a memoized selector - useful in cases where the the return value will
// always trigger a re-render, eg. filter() or map() which always return a new array reference.
// https://redux.js.org/usage/deriving-data-selectors#createselector-overview
// It can be passed arguments by passing in additional "selectors" in the first argument as an array.
// The return values of each input selector are the arguments passed to the memoized selector.
// https://redux.js.org/usage/deriving-data-selectors#passing-input-parameters
export const selectErrorsById = createSelector(
  [selectErrors, (_, id: string) => id],
  (errors, id) => errors.filter(e => e.questionId === id),
  {
    memoizeOptions: {
      // Deep equality check for the result array, needed since a new array is returned each time
      resultEqualityCheck: isEqual,
      // Cache size for unique selector instances. Since this selector is used in multiple
      // components simultaneously, this preserves memoization which would be lost otherwise.
      // Currently set to 105 for the maximum number of questions - may need to be updated in future
      maxSize: MAX_QUESTIONS_ON_PAGE,
    },
  }
);

/** The list of errors that exist for the specified type */
export const selectErrorsByType = createSelector(
  [selectErrors, (_, type: SurveyErrorType | SurveyErrorType[]) => type],
  (errors, type) => {
    const types = Array.isArray(type) ? type : [type];
    return errors.filter(e => types.includes(e.code));
  }
);

/** The meta info from an error */
export const selectErrorMetaByType = createSelector(
  [selectErrors, selectMeta, (_, type: SurveyErrorType | SurveyErrorType[]) => type],
  (errors, meta, type) => {
    const types = Array.isArray(type) ? type : [type];
    const errorType = errors.filter(e => types.includes(e.code))[0];

    if (errorType) {
      const defaultMeta = meta[errorType.code] ?? {};
      return { ...defaultMeta, ...errorType.meta };
    }
    return null;
  }
);

// ========== EXPORT

export default errorsSlice.reducer;
