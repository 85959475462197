import { SurveyTheme } from '../utils/types';
import { SurveyDesign, SurveyDesignWithTheme } from '../v2/types';

const combineSurveyDesignAndTheme = (
  surveyDesign: SurveyDesign,
  surveyTheme: SurveyTheme | null
): SurveyDesignWithTheme => {
  return {
    ...surveyDesign,
    theme: surveyTheme,
  };
};

export default combineSurveyDesignAndTheme;
