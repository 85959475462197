import { createSurveyStyles } from '@sm/webassets/SurveyTheme/context';
import { WrenchTheme } from '@wds/styles';
import { getContrastColor } from '@wds/utils';
import { isV2Theme } from '~app/helpers/themeLayout';

const getV2AccentColor = (logoBackgroundColor: string | undefined, titleColor: string | undefined): string => {
  if (logoBackgroundColor) {
    const contrastColor = getContrastColor(logoBackgroundColor);
    return contrastColor === 'dark' ? '#000' : '#fff';
  }
  return titleColor ?? '#000';
};

const useStyles = createSurveyStyles(theme => {
  const accentColor = isV2Theme(theme.name)
    ? getV2AccentColor(theme.logoStyle?.backgroundColor, theme.titleColor)
    : theme.titleColor;
  return {
    selectContainer: {
      fontWeight: 400,
      fontFamily: WrenchTheme.type.fontFamily.base,
      fontSize: WrenchTheme.type.fontSize.body, // 16px
      color: accentColor,
      borderRadius: 0,
      '& select': {
        backgroundColor: 'transparent',
        paddingRight: '1.5em', // 24px ((6px+6px+12px)÷16px)
        '&:focus-within, &:hover': {
          border: `1px solid ${accentColor}`,
        },
      },
      '& option': {
        color: '#000',
      },
      '& svg': {
        width: `0.75em`, // 12px (12px÷16px)
        height: `0.75em`, // 12px (12px÷16px)
      },
    },
  };
});

export default useStyles;
