/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ExternalQuestion, RespondentAnswerTypes } from '@sm/question-widgets/respondent-survey';
import Cookies from 'js-cookie';
import { AppDispatch, RootState } from '~app/storeV2';

import {
  QuestionAnswers,
  PageQuestions,
  SurveyTakingCurrentPage,
  StaticContextEnvironment,
  SubmitMutation,
  CurrentSessionSurvey,
} from '../types';

import { SurveyFormat } from '~app/components/Survey/SurveyFormat/constants';
import answersMatch from '~app/helpers/answersMatch';
import { transformToSurveyErrors } from '../../errors/transformToSurveyErrors';
import { mapSurveyAnswersToResponse } from '../../helper/mapSurveyAnswersToResponse';
import { deferToAfterFocusChanged, validate } from '../../validation';
import { removeErrorsById, setErrorsById } from './errorsSlice';
import { DEFAULT_SUBMIT_RESULT, REDIRECT_TYPE } from '../../constants';
import { RespApiAnswerInput, SubmitSurveyResponseError } from '~lib/generatedGqlTypes';
import { GetSpageSessionQuery } from '../queries/SpageSession.types';
import { Collector, Respondent, ExistingResponse } from '~app/pages/SurveyTaking/utils/types';
import getEndPageRedirectUrl from '../../helper/getEndPageRedirectUrl';
import mapResponseToSurveyAnswers from '../../helper/mapResponseToSurveyAnswers';
import { SubmitSurveyResponseMutation } from '../../SubmitResponses.types';
import { SurveyWithOverriddenLanguage } from '~helpers/pages/survey-taking';

// ========== INITIAL STATE
const emptyPageIds: readonly number[] = Object.freeze([]);
export type SurveyTakingPayload = {
  respondentSession: GetSpageSessionQuery['spageSession'];
  survey: SurveyWithOverriddenLanguage;
  surveyTakingCurrentPage: SurveyTakingCurrentPage;
  surveyChangedOnPageReload: boolean;
  endPageUrl: string;
  isEndPageUrlExternal: boolean;
  encrytedSurveyParams?: string;
  encryptedSmSubject?: string | null;
  isWhiteLabel: boolean;
  recipientId?: string | null;
};

export type SurveyMode = (typeof SurveyView)[keyof typeof SurveyView];
export type SurveyVersionDialog = {
  isVisible: boolean;
  shouldRefreshOnClose: boolean;
};

export function refreshPage(): void {
  try {
    if (typeof window !== 'undefined') {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'auto', // prevent smooth scroll
      });
      window.location.reload();
    }
  } catch (e) {
    console.error('Error refreshing page', e);
  }
}

export type SurveyState = {
  surveyTakingPayload?: SurveyTakingPayload;
  /** The survey object */
  survey?: CurrentSessionSurvey;
  /** The active page object */
  activePage?: SurveyTakingCurrentPage;
  /** The collector object */
  collector?: Collector;
  /**
   * The list of existing responses for this survey taker. List is empty if there are no existing responses.
   * This list of responses is used to also determine if changes are made to the existing answers. So this list
   * remains the same throughout the survey taking process (unless the browser's page is refreshed).
   *
   * Must be readonly to prevent accidental mutation.
   */
  readonly existingResponsesOnServer: ExistingResponse[];
  /** The respondent object */
  respondent?: Pick<Respondent, 'id'>;
  /** The client token */
  clientToken?: string;
  /** The answers for the current page */
  answers?: QuestionAnswers;
  questions?: PageQuestions;
  deleteQuestionIds: string[];
  /** To track what view is shown to user */
  surveyView: SurveyMode;
  /* Redirect URL based off collector's Survey End Page feature */
  surveyEndPageUrl: string | null;
  surveyEndPageUrlIsExternal: boolean;
  /* Encrypted SMParams when instant results is enabled */
  encryptedInstantResultsSmParam: string | null;
  currentPageId?: string;
  encrytedSurveyParams?: string;
  encryptedSmSubject?: string | null;
  isWhiteLabel: boolean;
  pageIds?: number[];
  // TODO: Collector Key null is it a valid case?
  collectorKey: string | null;
  /* StaticContext Environment */
  recipientId?: string | null;
  environment?: StaticContextEnvironment;
  surveyOwnerPackageId?: string;
  surveyChangedOnPageReload: boolean;
  surveyVersionDialog: SurveyVersionDialog;
};

/** Different views that are designed like a survey page but have different content */
export const SurveyView = {
  Taking: 'TAKING',
  ThankYou: 'THANK_YOU',
  Password: 'PASSWORD',
  CloseWindow: 'CLOSE_WINDOW',
} as const;

export const initialState: SurveyState = {
  surveyTakingPayload: undefined,
  survey: undefined,
  activePage: undefined,
  collector: undefined,
  clientToken: undefined,
  questions: { items: [] },
  answers: {},
  deleteQuestionIds: [],
  surveyView: SurveyView.Taking,
  surveyEndPageUrl: 'abc',
  surveyEndPageUrlIsExternal: false,
  encryptedInstantResultsSmParam: 'abc',
  currentPageId: undefined,
  pageIds: undefined,
  collectorKey: null,
  recipientId: null,
  encrytedSurveyParams: undefined,
  environment: undefined,
  surveyChangedOnPageReload: false,
  existingResponsesOnServer: [],
  isWhiteLabel: false,
  surveyVersionDialog: {
    isVisible: false,
    shouldRefreshOnClose: false,
  },
};

type SurveyCompleteAction = {
  reqLocale: string;
};

type SetCookieArgs = {
  name: string;
  value: string;
  expiration?: number | Date;
};

type SurveyPagePayload = {
  activePage?: SurveyTakingCurrentPage;
  questions?: PageQuestions;
  answers?: QuestionAnswers;
  clientToken: string;
  encryptedSmSubject: string;
};

type PostSurveyCompleteCleanupArgs = {
  collectorKey: string | null;
};

export const hasInvalidSurveyVersionError = (userErrors: Pick<SubmitSurveyResponseError, 'code'>[] | null): boolean => {
  if (userErrors) {
    return userErrors.some(error => error.code === 'INVALID_SURVEY_VERSION');
  }
  return false;
};

// ========== SLICE

const sliceName = 'survey';

export const surveySlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    initEnvironment: (state, action: PayloadAction<StaticContextEnvironment>) => {
      state.environment = action.payload;
      state.surveyVersionDialog = {
        isVisible: false,
        shouldRefreshOnClose: false,
      };
    },
    initializeCollector: (state, action: PayloadAction<Collector>) => {
      state.collector = action.payload;
    },

    handleSurveyVersionChange: state => {
      if (state.respondent?.id) {
        /**
         * If the respondent is already set, refresh the page to get the latest survey version and show the survey change
         * dialog on page reload.
         */
        refreshPage();
      } else {
        /**
         * If the respondent is not set, show the survey change dialog immediately and let he refresh happen after
         * the user clicks the confirmation button. We do this because the user is on a single page or the first page.
         */
        state.surveyVersionDialog = {
          isVisible: true,
          shouldRefreshOnClose: true,
        };
      }
    },
    showSurveyVersionDialog: (state, action: PayloadAction<Pick<SurveyVersionDialog, 'shouldRefreshOnClose'>>) => {
      state.surveyVersionDialog = {
        isVisible: true,
        shouldRefreshOnClose: action.payload.shouldRefreshOnClose,
      };
    },
    hideSurveyVersionDialog: state => {
      state.surveyVersionDialog.isVisible = false;
    },
    initializeExistingResponses: (state, action: PayloadAction<ExistingResponse[]>) => {
      state.existingResponsesOnServer = action.payload;
      if (state.questions) {
        state.answers = mapResponseToSurveyAnswers(
          action.payload,
          (state.questions?.items as ExternalQuestion[]) ?? []
        );
      }
    },
    initializeRespondent: (
      state,
      action: PayloadAction<Pick<Respondent, 'id' | 'encryptedInstantResultsSmParam'> | null>
    ) => {
      if (action.payload !== null) {
        state.respondent = { id: action.payload.id };
        state.encryptedInstantResultsSmParam = action.payload.encryptedInstantResultsSmParam;
      } else {
        state.respondent = undefined;
        state.encryptedInstantResultsSmParam = null;
      }
    },
    initializeSurvey: (state, action: PayloadAction<SurveyTakingPayload>) => {
      state.surveyTakingPayload = action.payload;
      state.survey = action.payload.survey;
      state.activePage = action.payload.surveyTakingCurrentPage ?? {};
      state.pageIds = action.payload.respondentSession.pageIds;
      state.currentPageId = action.payload.surveyTakingCurrentPage?.surveyPage?.id;
      state.questions = action.payload.surveyTakingCurrentPage?.surveyPage?.surveyPageQuestions;
      state.encrytedSurveyParams = action.payload.encrytedSurveyParams;
      state.surveyChangedOnPageReload = action.payload.surveyChangedOnPageReload;
      state.surveyEndPageUrl = action.payload.endPageUrl;
      state.surveyEndPageUrlIsExternal = action.payload.isEndPageUrlExternal;
      state.isWhiteLabel = action.payload.isWhiteLabel;
      state.recipientId = action.payload.recipientId;
      state.encryptedSmSubject = action.payload.encryptedSmSubject;

      // initialize answers
      state.answers =
        action.payload.surveyTakingCurrentPage?.surveyPage?.surveyPageQuestions?.items?.reduce(
          (acc, curr) => ({
            ...acc,
            [curr.id]: {
              questionId: curr.id,
              values: [],
              touched: false,
              isDirty: false,
            },
          }),
          {}
        ) ?? {};
    },
    setSurveyChangedOnPageReload: (state, action: PayloadAction<boolean>) => {
      state.surveyChangedOnPageReload = action.payload;
    },
    setAnswers: (state, action: PayloadAction<QuestionAnswers | undefined | {}>) => {
      state.answers = action.payload;
    },
    setQuestions: (state, action: PayloadAction<PageQuestions>) => {
      state.questions = action.payload;
    },
    setActivePage: (state, action: PayloadAction<SurveyPagePayload>) => {
      const { clientToken, activePage, questions = { items: [] } } = action.payload;
      state.activePage = activePage;
      state.questions = questions;
      state.currentPageId = activePage?.surveyPage?.id;
      const { answers } = state;
      const answersConvertedFromBackend = mapResponseToSurveyAnswers(
        state.existingResponsesOnServer,
        (questions?.items as ExternalQuestion[]) ?? []
      );
      state.encryptedSmSubject = action.payload.encryptedSmSubject;
      if (clientToken) {
        state.clientToken = clientToken;
      }
      if (answers) {
        const mergedAnswers: QuestionAnswers = {};
        questions?.items.forEach(question => {
          if (answers[question.id]?.values) {
            mergedAnswers[question.id] = answers[question.id];
          } else if (answersConvertedFromBackend[question.id]?.values) {
            mergedAnswers[question.id] = answersConvertedFromBackend[question.id];
          }
        });
        state.answers = { ...answers, ...mergedAnswers };
      } else {
        state.answers = answersConvertedFromBackend;
      }
    },
    updateAnswers: (state, action: PayloadAction<QuestionAnswers>) => {
      const mergedAnswers = {
        ...state.answers,
        ...action.payload,
      };
      state.answers = mergedAnswers;
    },
    updateDeleteQuestionIds: (state, action: PayloadAction<string>) => {
      state.deleteQuestionIds = [...state.deleteQuestionIds, action.payload];
    },
    resetDeleteQuestionIds: state => {
      state.deleteQuestionIds = [];
    },
    setClientToken: (state, action: PayloadAction<string>) => {
      state.clientToken = action.payload;
    },
    setCurrentPageId: (state, action: PayloadAction<string>) => {
      state.currentPageId = action.payload;
    },
    setEncrytedSurveyParams: (state, action: PayloadAction<string>) => {
      state.encrytedSurveyParams = action.payload;
    },
    setPageIds: (state, action: PayloadAction<number[]>) => {
      state.pageIds = action.payload;
    },
    setCollectorKey: (state, action: PayloadAction<string>) => {
      state.collectorKey = action.payload;
    },
    setSurveyView: (state, action: PayloadAction<SurveyMode>) => {
      state.surveyView = action.payload;
    },
    setSurveyOwnerPackageId: (state, action: PayloadAction<string>) => {
      state.surveyOwnerPackageId = action.payload;
    },
    clearCookie: (state, action: PayloadAction<string>) => {
      const { environment: { domain, tld } = {} } = state;
      Cookies.remove(action.payload, { path: '/', domain: `.${domain}.${tld}` });
    },
    setCookie: (state, action: PayloadAction<SetCookieArgs>) => {
      const { environment: { domain, tld } = {} } = state;
      const { name, value, expiration } = action.payload;

      Cookies.set(name, value, {
        expires: expiration,
        domain: `.${domain}.${tld}`,
        secure: true,
        sameSite: 'None',
      });
    },
    surveyComplete: (state, action: PayloadAction<SurveyCompleteAction>) => {
      const redirectType = state.collector?.surveyEndPageOptions?.redirectType;
      const isCloseWindowRedirectType = redirectType === REDIRECT_TYPE.closeWindow;
      const isLoopRedirectType = redirectType === REDIRECT_TYPE.loop;
      const isThankYouEnabled = state.collector?.thankYouPage?.isEnabled ?? false;
      const surveyResultsUrl = state.collector?.surveyResultsUrl ?? null;

      const { reqLocale } = action.payload;

      const { environment } = state;
      const isCurrentViewCustomThankYou = state.surveyView === SurveyView.ThankYou;

      const showQuizResultsPage = state.survey?.isQuiz && state.survey.quizOptions?.showResults;
      const showInstantResultsPage = !showQuizResultsPage && surveyResultsUrl;
      const showThankYouView = !showInstantResultsPage && isThankYouEnabled && !isCurrentViewCustomThankYou;
      const surveyEndPageRedirect = !showThankYouView;

      let redirectUrl: string | null = null;
      const redirectUrlParams = `?sm=${state.encrytedSurveyParams}&lang=${reqLocale}`;

      if (showQuizResultsPage) {
        if (surveyResultsUrl) {
          Cookies.set('sm_ir', state.encryptedInstantResultsSmParam ?? '', {
            domain: `.${environment?.domain}.${environment?.tld}`,
            secure: true,
            sameSite: 'None',
          });
        }

        redirectUrl = `/r/quiz/results${redirectUrlParams}`;
      } else if (showInstantResultsPage) {
        Cookies.set('sm_ir', state.encryptedInstantResultsSmParam ?? '', {
          domain: `.${environment?.domain}.${environment?.tld}`,
          secure: true,
          sameSite: 'None',
        });

        redirectUrl = `${surveyResultsUrl}${redirectUrlParams}`;
      } else if (showThankYouView) {
        state.surveyView = SurveyView.ThankYou;
      } else if (isCloseWindowRedirectType) {
        state.surveyView = SurveyView.CloseWindow;
      } else if (surveyEndPageRedirect) {
        redirectUrl = getEndPageRedirectUrl({
          redirectUrl: state.surveyEndPageUrl,
          encryptedParams: state.encrytedSurveyParams,
          locale: reqLocale,
          skipUrlParams: isLoopRedirectType || state.surveyEndPageUrlIsExternal,
        });
      }

      if (redirectUrl) {
        window.location.replace(redirectUrl);
      }
    },
    reset: () => initialState,
    postSurveyCompleteCleanup: (state, action: PayloadAction<PostSurveyCompleteCleanupArgs>) => {
      const { collectorKey } = action.payload;
      Cookies.remove(`RE_${collectorKey}`, { path: '/' });
      Cookies.remove(`REPID_${collectorKey}`, { path: '/' });
    },
  },
});

// ========== ACTIONS

export const {
  initializeSurvey,
  initializeCollector,
  initializeExistingResponses,
  initializeRespondent,
  initEnvironment,
  setAnswers,
  setQuestions,
  setActivePage,
  updateAnswers,
  updateDeleteQuestionIds,
  resetDeleteQuestionIds,
  setClientToken,
  reset,
  setSurveyView,
  surveyComplete,
  setCurrentPageId,
  setEncrytedSurveyParams,
  setPageIds,
  setCollectorKey,
  setCookie,
  clearCookie,
  setSurveyOwnerPackageId,
  postSurveyCompleteCleanup,
  setSurveyChangedOnPageReload,
  hideSurveyVersionDialog,
  showSurveyVersionDialog,
  handleSurveyVersionChange,
} = surveySlice.actions;

// ========== THUNKS

/**
 * Update an answer - the specific answer is inferred by the `questionId` inside the answer object.
 * Error validation is also run for the new answer and the errors state is updated accordingly.
 *
 */
export const updateAnswer = createAsyncThunk(`${sliceName}/updateAnswer`, (answer: RespondentAnswerTypes, thunkAPI) => {
  const { questionId } = answer;
  const { surveyState } = thunkAPI.getState() as RootState;
  const { answers, deleteQuestionIds } = surveyState;
  const dispatch = thunkAPI.dispatch as AppDispatch;

  if (!answer.touched) {
    return; // no need for updates if answer was not touched
  }

  const { isValid, errors } = validate(questionId);
  deferToAfterFocusChanged(() => {
    // defer to make done with error-change click work
    if (!isValid) {
      const questionErrors = transformToSurveyErrors(errors);
      dispatch(setErrorsById({ id: questionId, newErrors: questionErrors }));
    } else {
      dispatch(removeErrorsById(questionId));
    }
  });

  const currentAnswer = answers?.[questionId];
  const hasExistingValues = !!currentAnswer?.values?.length;
  const isAlreadyDeletedQuestion = deleteQuestionIds.includes(questionId);
  const doAnswersMatch = answersMatch(answer.values, currentAnswer?.values ?? []);

  if (!isAlreadyDeletedQuestion && hasExistingValues && !doAnswersMatch) {
    dispatch(updateDeleteQuestionIds(questionId));
  }

  dispatch(
    updateAnswers({
      [questionId]: { ...(surveyState.answers?.[questionId] ?? {}), ...answer },
    })
  );
});

/*
 * Submit survey response to responsesAPI.
 *
 * Currently requires the mutation function to be passed as a parameter as it is generated by hook.
 * Note: dateStarted and dateCompleted also need to be passed in as part of a solution to
 * fix failing integration tests involving mocked timers and promises.
 */
export const submitResponses = createAsyncThunk(
  `${sliceName}/submitResponses`,
  async (
    {
      mutation,
      dateStarted,
      dateCompleted,
      reqLocale,
      respondentId,
      isFinalSubmit,
      collectorKey,
      isValid,
      isPrevious,
      confirmationEmail,
    }: {
      mutation: SubmitMutation;
      dateStarted: string;
      dateCompleted: string;
      reqLocale: string;
      respondentId: string;
      isFinalSubmit: boolean;
      collectorKey: string;
      isValid: boolean;
      isPrevious: boolean;
      confirmationEmail: string | null;
    },
    { getState, dispatch }
  ): Promise<NonNullable<SubmitSurveyResponseMutation['submitSurveyResponse']>> => {
    const { surveyState } = getState() as RootState;
    if (surveyState.survey && surveyState.collector) {
      try {
        // Only use the answered questions in payload (SPAGE-7410)
        const pageId = surveyState.activePage?.surveyPage?.id ?? '';
        const responses = mapSurveyAnswersToResponse({
          questions: (surveyState.activePage?.surveyPage?.surveyPageQuestions?.items ?? []) as ExternalQuestion[],
          answers: surveyState.answers ?? {},
          pageId,
          isValid,
          isPrevious,
        }) as Required<RespApiAnswerInput[]>; // this typecast is necessary because gql InputMaybe types expect null values, which are not allowed in the response

        const { id: collectorId, isAnonymous, thankYouPage, surveyResultsUrl } = surveyState.collector;
        const {
          version,
          language: { overriddenId: surveyLangId, overriddenCode: languageCode },
          isQuiz,
          quizOptions,
        } = surveyState.survey;
        const generateQuizResultsSMParams = isQuiz && (quizOptions?.showResults ?? false);

        const data = await mutation({
          variables: {
            surveyId: surveyState.survey.id,
            languageCode,
            input: {
              respondentId,
              collectorId,
              isAnonymous: isAnonymous ?? false,
              surveyVersion: version,
              dateStarted,
              dateCompleted,
              responses,
              generateQuizResultsSMParams,
              surveyLangId,
              redirectUrl: surveyState.surveyEndPageUrl,
              reqLocale,
              isThankYouEnabled: thankYouPage?.isEnabled ?? false,
              instantResultsUrl: surveyResultsUrl ?? null,
              confirmationEmail,
              deleteQuestionIds: surveyState.deleteQuestionIds,
              collectorKey: surveyState.collectorKey,
              isSurveyComplete: isFinalSubmit,
              isPreviousPage: isPrevious,
              recipientId: surveyState.recipientId ?? null,
            },
          },
        });

        dispatch(resetDeleteQuestionIds());
        if (data.data?.submitSurveyResponse) {
          return data.data.submitSurveyResponse;
        }

        const errorMessage =
          data.errors?.map(error => error.message).join(', ') ?? 'Unexpected response when submiting responses.';

        return {
          ...DEFAULT_SUBMIT_RESULT,
          success: false,
          userErrors: [
            {
              code: 'unexpected_error',
              detail: errorMessage,
              field: null,
              questionId: null,
            },
          ],
        };
      } catch (error) {
        // To be completed in server error validation story
        if (
          error !== null &&
          typeof error === 'object' &&
          'message' in error &&
          error.message === 'unexpected_page_id'
        ) {
          return {
            ...DEFAULT_SUBMIT_RESULT,
            success: false,
            userErrors: [
              {
                code: 'unexpected_page_id',
                detail: 'Unexpected page ID when submiting responses.',
                field: null,
                questionId: null,
              },
            ],
          };
        }
      }
    }

    return {
      ...DEFAULT_SUBMIT_RESULT,
      surveyPage: null,
    };
  }
);

// ========== SELECTORS

/** The survey state object */
export const selectSurveyState = (state: RootState): SurveyState => state.surveyState;

/** The survey object */
export const selectSurvey = createSelector(selectSurveyState, surveyState => surveyState.survey);

/** The current page object */
export const selectActivePage = createSelector(selectSurveyState, surveyState => surveyState.activePage);

/** The collector object */
export const selectCollector = createSelector(selectSurveyState, surveyState => surveyState.collector);

/** The client token */
export const selectClientToken = createSelector(selectSurveyState, surveyState => surveyState.clientToken);

export const selectSurveyView = createSelector(selectSurveyState, surveyState => surveyState.surveyView);

export const selectDialogState = createSelector(selectSurveyState, state => state.surveyVersionDialog);
export const selectIsDialogVisible = createSelector(selectDialogState, state => state.isVisible);
export const selectShouldRefreshOnClose = createSelector(selectDialogState, state => state.shouldRefreshOnClose);

/** Survey package id */
export const selectSurveyOwnerPackageId = createSelector(
  selectSurveyState,
  surveyState => surveyState.surveyOwnerPackageId
);

/** The survey format (eg. classic or OQAAT) */
export const selectSurveyFormat = createSelector(
  selectSurvey,
  survey => survey?.format ?? SurveyFormat.ONE_QUESTION_AT_A_TIME
);

/** Object containing all answers for the current page */
export const selectAnswers = createSelector(selectSurveyState, surveyState => surveyState.answers);

export const selectQuestionsWithAnswersCount = createSelector(selectSurveyState, surveyState => {
  const { existingResponsesOnServer, answers } = surveyState;
  // Extract unique question IDs from server responses
  const existingQuestionsSet = new Set(existingResponsesOnServer.map(response => response.questionId));

  // Process answers on current page
  Object.entries(answers ?? {}).forEach(([questionId, answer]) => {
    // If answer has empty values array, remove from set (question was skipped/removed)
    if (Array.isArray(answer.values) && answer.values.length === 0 && existingQuestionsSet.has(questionId)) {
      existingQuestionsSet.delete(questionId);
    } else {
      // Otherwise, add to set (question was answered)
      existingQuestionsSet.add(questionId);
    }
  });

  return existingQuestionsSet.size;
});

/** Object containing all answers for the current page */
export const selectQuestions = createSelector(selectSurveyState, surveyState => surveyState.questions);

/** The answer object for the question specified by Id */
export const selectAnswerById = createSelector(
  [selectAnswers, (_, questionId: string) => questionId],
  (answers, questionId) => answers?.[questionId]
);

/**
 * The total number of questions that have a value (i.e. answered), on the current page
 * note: currently only works with single-value questions
 * TODO: refactor to include answered questions on other pages once we get that data from the backend
 *  */
export const selectTotalAnswers = createSelector(selectAnswers, answers =>
  !answers ? 0 : Object.values(answers).reduce((acc: number, answer) => (answer.values?.[0]?.value ? acc + 1 : acc), 0)
);
export const selectPageIds = createSelector(selectSurveyState, surveyState => surveyState.pageIds ?? emptyPageIds);

export const selectCurrentPageId = createSelector(
  selectSurveyState,
  surveyState => surveyState.activePage?.surveyPage?.id
);

export const selectCollectorKey = createSelector(selectSurveyState, surveyState => surveyState.collectorKey);

export const selectEncrytedSurveyParams = createSelector(
  selectSurveyState,
  surveyState => surveyState.encrytedSurveyParams
);

export const selectEncryptedSmSubject = createSelector(
  selectSurveyState,
  surveyState => surveyState.encryptedSmSubject
);

export const selectSurveyChangedOnPageReload = createSelector(
  selectSurveyState,
  surveyState => surveyState.surveyChangedOnPageReload
);

export const selectIsWhiteLabel = createSelector(selectSurveyState, surveyState => surveyState.isWhiteLabel);

// ========== EXPORT

export default surveySlice.reducer;
