import { createSurveyStyles } from '@sm/webassets/SurveyTheme/context';
import { isV2Theme } from '~app/helpers/themeLayout';

type StyleProps = {
  isRTL: boolean;
};

const useSurveyStyles = createSurveyStyles(theme => ({
  pageTitle: {
    color: theme?.titleColor,
    lineHeight: 1.25,
    marginTop: 0,
    marginBottom: 12,
    ...(isV2Theme(theme.name) && {
      WebkitFontSmoothing: 'antialiased',
    }),
    ...theme?.pageTitle,
    // reset font weight changed by the theme above
    '& strong': {
      fontWeight: 'bold !important',
    },
  },
  questionNumber: ({ isRTL }: StyleProps) => ({
    margin: isRTL ? '0 0 0 0.25em' : '0 0.25em 0 0',
  }),
}));

export default useSurveyStyles;
