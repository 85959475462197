import combineSurveyDesignAndTheme from './combineSurveyDesignAndTheme';
import { CurrentSessionSurvey } from '../v2/types';
import { SurveyTheme } from '../utils/types';
import { SurveyWithOverriddenLanguage } from '~helpers/pages/survey-taking';

export default function createCurrentSessionSurvey(
  survey: SurveyWithOverriddenLanguage,
  theme: SurveyTheme
): CurrentSessionSurvey {
  return { ...survey, design: survey?.design ? combineSurveyDesignAndTheme(survey?.design, theme) : null };
}
