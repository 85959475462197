import { MetricsTracker, USER_EVENTS } from '@sm/metrics';
import { ANALYTICS_EVENTS } from '~app/components/amplitudeEnums';
import { CurrentSessionSurvey, PageQuestions } from '~app/pages/SurveyTaking/v2/types';
import { SubmitSurveyResponseMutation } from '~app/pages/SurveyTaking/SubmitResponses.types';
import { Collector } from '~app/pages/SurveyTaking/utils/types';
import generateEventData from './generateEventData';

type SendAmplitudeEvent = {
  survey?: CurrentSessionSurvey;
  collector?: Collector;
  respondent?: NonNullable<SubmitSurveyResponseMutation['submitSurveyResponse']>['respondent'];
  surveyOwnerPackageId?: string;
  questions?: PageQuestions;
  confirmationEmailCheckboxIsChecked?: boolean;
  isMultilingual?: boolean;
};
export default function sendAmplitudeEvent(
  event: ANALYTICS_EVENTS,
  {
    survey,
    collector,
    surveyOwnerPackageId,
    respondent,
    questions,
    confirmationEmailCheckboxIsChecked,
    isMultilingual,
  }: SendAmplitudeEvent
): void {
  const amplitudeData = generateEventData({
    collector,
    survey,
    surveyOwnerPackageId,
    confirmationEmailCheckboxIsChecked,
    isMultilingual,
  });
  if (event === ANALYTICS_EVENTS.SURVEY_LOADED && questions) {
    amplitudeData.numQuestionsOnPage = questions.items.length;
    amplitudeData.numRequiredQuestionsOnPage = questions.items.filter(
      question => 'required' in question && question.required
    ).length;
  }

  MetricsTracker.track({
    name: USER_EVENTS.PAGE_VIEW,
    data: { amplitudeEvent: event, ...amplitudeData, respondentId: respondent?.id },
  });
}
