import { createSurveyStyles } from '@sm/webassets/SurveyTheme/context';
import { isV2Theme } from '~app/helpers/themeLayout';

const useSurveyStyles = createSurveyStyles(theme => ({
  pageDescription: {
    color: theme.questionColor,
    lineHeight: 1.25,
    paddingBottom: 15,
    ...theme?.questionTitle,
    // reset font weight changed by the theme above
    '& strong': {
      fontWeight: 'bold !important',
    },
    ...(isV2Theme(theme.name) && {
      color: theme.pageDescription?.color,
      fontSize: theme.pageDescription?.fontSize,
      fontWeight: theme.pageDescription?.fontWeight,
      fontStyle: theme.pageDescription?.fontStyle,
      textDecoration: theme.pageDescription?.textDecoration,
      WebkitFontSmoothing: 'antialiased',
    }),
  },
}));

export default useSurveyStyles;
