import { createSurveyStyles } from '@sm/webassets/SurveyTheme/context';
import { isV2Theme } from '~app/helpers/themeLayout';
import { SurveyTitleHorizontalAlignment } from '~lib/generatedGqlTypes';

const calculateSurveyTitleWidth = (v2Theme: boolean, align: SurveyTitleHorizontalAlignment): string | null => {
  if (align === 'CENTER') {
    return null;
  }
  return v2Theme ? '95%' : '100%';
};

const useSurveyStyles = createSurveyStyles<
  'surveyTitle' | 'surveyTitleWrapper',
  {
    align: SurveyTitleHorizontalAlignment;
    logoVerticalAlignment: 'BASELINE' | 'BOTTOM' | undefined;
    showTitleText: boolean;
  }
>(theme => ({
  surveyTitle: {
    color: theme?.titleColor ?? '#00bf6f',
    lineHeight: 1.25,
    paddingBottom: 24,
    margin: 0,
    width: ({ align }) => calculateSurveyTitleWidth(isV2Theme(theme.name), align),
    // use `start` to support RTL (as 'LEFT' means `right` in RTL languages)
    textAlign: ({ align }) => (align === 'CENTER' ? 'center' : 'start'),
    ...theme?.surveyTitle,
    // reset font weight changed by the theme above
    '& strong': {
      fontWeight: 'bold !important',
    },
    ...(isV2Theme(theme.name) && {
      WebkitFontSmoothing: 'antialiased',
      paddingBottom: ({ logoVerticalAlignment }) => (logoVerticalAlignment === 'BASELINE' ? 0 : 16),
      paddingLeft: ({ logoVerticalAlignment }) => (logoVerticalAlignment === 'BASELINE' ? 16 : 0),
    }),
    [`@media (max-width: ${theme.breakpoints?.xs.max})`]: {
      ...(isV2Theme(theme.name) && {
        paddingLeft: ({ logoVerticalAlignment, align }) =>
          logoVerticalAlignment === 'BASELINE' && align === 'CENTER' ? 0 : null,
      }),
    },
  },
  surveyTitleWrapper: ({ logoVerticalAlignment, align, showTitleText }) => ({
    ...(isV2Theme(theme.name) && {
      paddingTop: showTitleText || logoVerticalAlignment === 'BASELINE' || logoVerticalAlignment === 'BOTTOM' ? 16 : 0,

      ...(logoVerticalAlignment === 'BASELINE' && {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'start',
        ...(align === 'CENTER' && {
          justifyContent: 'center',
          [`@media (max-width: ${theme.breakpoints?.xs.max})`]: {
            flexDirection: 'column',
            gap: 16,
          },
        }),
      }),

      ...((logoVerticalAlignment === 'BASELINE' || logoVerticalAlignment === 'BOTTOM') && {
        paddingBottom: 16,
      }),
    }),
  }),
}));

export default useSurveyStyles;
