/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
/**
 * Functions below come together from many sources:
 * @see https://gist.github.com/clauderic/13cc9207a9e5db63ee67a1588eb11811
 * @see https://gist.github.com/gre/1650294
 * Added TypeScript and some modifications
 */

export type EasingNames =
  | 'linear'
  | 'easeInQuad'
  | 'easeOutQuad'
  | 'easeInOutQuad'
  | 'easeInCubic'
  | 'easeOutCubic'
  | 'easeInOutCubic'
  | 'easeInQuart'
  | 'easeOutQuart'
  | 'easeInOutQuart'
  | 'easeInQuint'
  | 'easeOutQuint'
  | 'easeInOutQuint';

/**
 * @name EasingFunctions- Useful easing equations
 * @comment only considering the t value for the range [0, 1] => [0, 1]
 */
/* istanbul ignore next */
export const EasingFunctions: EasingMap = {
  /** @comment linear - no easing, no acceleration */
  linear: (t: number) => t,
  /** @comment easeInQuad - accelerating from zero velocity */
  easeInQuad: (t: number) => t * t,
  /** @comment easeOutQuad - decelerating to zero velocity */
  easeOutQuad: (t: number) => t * (2 - t),
  /** @comment easeInOutQuad - acceleration until halfway, then deceleration */
  easeInOutQuad: (t: number) => (t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t),
  /** @comment easeInCubic - accelerating from zero velocity */
  easeInCubic: (t: number) => t * t * t,
  /** @comment easeOutCubic - decelerating to zero velocity */
  easeOutCubic: (t: number) => --t * t * t + 1,
  /** @comment easeInOutCubic - acceleration until halfway, then deceleration */
  easeInOutCubic: (t: number) => (t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1),
  /** @comment easeInQuart - accelerating from zero velocity */
  easeInQuart: (t: number) => t * t * t * t,
  /** @comment easeOutQuart - decelerating to zero velocity */
  easeOutQuart: (t: number) => 1 - --t * t * t * t,
  /** @comment easeInOutQuart - acceleration until halfway, then deceleration */
  easeInOutQuart: (t: number) => (t < 0.5 ? 8 * t * t * t * t : 1 - 8 * --t * t * t * t),
  /** @comment easeInQuint - accelerating from zero velocity */
  easeInQuint: (t: number) => t * t * t * t * t,
  /** @comment easeOutQuint - decelerating to zero velocity */
  easeOutQuint: (t: number) => 1 + --t * t * t * t * t,
  /** @comment easeInOutQuint - acceleration until halfway, then deceleration */
  easeInOutQuint: (t: number) => (t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * --t * t * t * t * t),
};
export type EasingEquation = (t: number) => number;
export type EasingMap = Record<EasingNames, EasingEquation>;
export type EasingType = keyof EasingMap | EasingEquation;

/**
 * Given a start/end point of a scroll and time elapsed, calculate the scroll position we should be at
 */
function getValue(
  start: number,
  end: number,
  elapsed: number,
  duration: number,
  easingType: EasingType = 'linear'
): number {
  if (elapsed > duration) return end;
  const easing = typeof easingType === 'string' ? EasingFunctions[easingType] : easingType;
  return start + (end - start) * easing(elapsed / duration);
}

export const Test__getValue = getValue;

type AnimateTick = () => void;
type AnimateCallback = () => void;
type AnimateProps = {
  /** the initial value */
  fromValue: number;
  /** the end value */
  toValue: number;
  /** the desired duration of the scroll animation */
  duration?: number;
  /** the timing function or easing-equation name */
  easingType?: EasingType;
  /** A callback that is fired once the scroll animation ends */
  onComplete: AnimateCallback;
  /** A function that is called before the animation starts */
  onStart?: () => void;
  /** A function that is called on each tick */
  onUpdate: (value: number, callback: AnimateTick | AnimateCallback) => void;
};

/**
 * Smoothly animate between two values
 */
export default function animate({
  fromValue,
  toValue,
  onStart,
  onUpdate,
  onComplete,
  duration = 600,
  easingType = 'easeOutQuart',
}: AnimateProps): void {
  const startTime = performance.now();

  const tick: AnimateCallback = () => {
    const elapsed = performance.now() - startTime;
    const time = getValue(fromValue, toValue, elapsed, duration, easingType);
    const callback = elapsed <= duration ? tick : onComplete;
    const updateAnimation = (): void => {
      onUpdate(time, callback);
    };
    window.requestAnimationFrame(updateAnimation);
  };

  onStart?.();
  tick();
}
