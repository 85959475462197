import React, { useCallback, useContext, useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useMutation } from '@apollo/client';
import { defineMessages, L10NContext, T, t } from '@sm/intl';
import {
  RespondentSurveyCapabilityProvider,
  ExternalQuestion,
  RespondentSurveyCapabilityProviderProps,
} from '@sm/question-widgets/respondent-survey';
import { getClientEnvironmentDetails, sanitizeString } from '@sm/utils';
import { useSurveyTheme } from '@sm/webassets/SurveyTheme/context';
import classNames from 'classnames';
import { FiveHundredErrorPage } from '@sm/webassets/FiveHundredError';
import { MetricsTracker, USER_EVENTS } from '@sm/metrics';
import { SubmitSurveyResponseMutation, SubmitSurveyResponseMutationVariables } from '../SubmitResponses.types';
import submitSurveyResponse from '../SubmitResponses.graphql';
import { BypassBlock } from '~app/components/core/BypassBlock';
import { SurveyTitle } from '~app/components/Survey/SurveyTitleV2';
import createHeadingLevel from '../helper/createHeadingLevel';
import SurveyFooter from '~app/components/Survey/FooterContainer';
import { PageTitle } from '~app/components/Survey/PageTitle';
import {
  ANALYTICS_EVENTS,
  ANALYTICS_INTERACTION_TYPE,
  ANALYTICS_LOCATION,
  ANALYTICS_WEBTYPE_TYPE,
} from '~app/components/amplitudeEnums';
import { PageDescription } from '~app/components/Survey/PageDescription';
import { SurveyButton } from '~app/components/Survey/SurveyButton';
import { Layout } from '../components/Layout';
import { QuestionList } from '../components/QuestionList';
import { PersistentProgressBar } from '~app/components/Survey/PersistentProgressBar';
import ModifiedSurveyDialog from '~app/components/Survey/ModifiedSurveyDialog';
import extrapolateSurveyData from '../helper/extrapolateSurveyData';
import useStyles from '../useStyles';
import { Logo } from '../components/Logo';
import { PageNavigation } from '../components/PageNavigation';
import { ClassicProgressBar } from '~app/components/Survey/ClassicProgressBar';
import { ActiveViewScrollManager, SurveyFormatView } from '~app/components/Survey/SurveyFormat';
import SurveyFormat from '../helper/surveyFormatEnum';
import WeblinkConfirmationCheckbox from '~app/components/Survey/WeblinkConfirmationCheckbox';
import { runAfterFocusChangedFn, useGlobalAfterFocusChangedHandler } from '../validation/delayedValidation';
import { useLayoutEffect, useAppSelectorV2 as useAppSelector, useAppDispatchV2 as useAppDispatch } from '~app/hooks';
import { isV2Theme, isOQAATFullBleed } from '~app/helpers/themeLayout';
import { decodeHtmlEntities } from '../helper/decodeHtmlEntities';
import { setErrors, focusFirstQuestionError, validatePage, validateConfirmationEmail } from './slices/errorsSlice';
import { isThankYouView, isSurveyTakingView, isCloseWindowView } from '../helper/currentSurveyView';
// needed to add SMQuestions copy to the translations list
import '../smQuestionsCopy';

import {
  selectSurvey,
  selectActivePage,
  selectCollector,
  selectQuestions,
  selectAnswers,
  updateAnswer,
  submitResponses,
  selectClientToken,
  selectSurveyView,
  surveyComplete,
  selectPageIds,
  selectCurrentPageId,
  selectCollectorKey,
  selectSurveyOwnerPackageId,
  selectEncryptedSmSubject,
  setActivePage,
  setCookie,
  postSurveyCompleteCleanup,
  selectQuestionsWithAnswersCount,
  setEncrytedSurveyParams,
  selectIsWhiteLabel,
  selectIsDialogVisible,
  selectShouldRefreshOnClose,
  hideSurveyVersionDialog,
  refreshPage,
  hasInvalidSurveyVersionError,
  handleSurveyVersionChange,
  initializeRespondent,
} from './slices/surveySlice';

import { LanguageSelect } from '../components/LanguageSelect';
import sendAmplitudeEvent from '~helpers/sendAmplitudeEvent';

const COPY = defineMessages({
  SKIP_LINK_CONTENT: {
    id: 'SurveyTaking.Survey.SkipLinkContent',
    defaultMessage: 'Skip to content',
    description: '[Type: label][Vis: med] - Skip to content link label (Accessibility)',
  },
  OK_LABEL: {
    id: 'SurveyTaking.Survey.OK_LABEL',
    defaultMessage: 'OK',
    description: '[Type: button][Vis: high] - question/description ok label for One-Question-At-A-Time mode',
  },
});

type SurveyProps = {
  pageRequestId: string;
  showRequestId: boolean;
  hasSurveyVersionChanged?: boolean;
  /** Local or Language code (e.g. `en` or `fr-CA`) */
  reqLocale?: string;
  /** https://reactjs.org/docs/dom-elements.html#suppresshydrationwarning */
  suppressHydrationWarning?: true;
  showWeblinkConfirmationToggle?: boolean;
};

export type v2SurveyTitleStyleProps = {
  themeName: string;
  isOQAAT: boolean;
  backgroundColor?: string;
};

const useV2Styles = createUseStyles({
  surveyTitleContainerV2: {
    paddingLeft: '40px',
    paddingRight: '40px',
    backgroundColor: ({ backgroundColor }: v2SurveyTitleStyleProps) => backgroundColor,
    position: ({ isOQAAT, themeName }: v2SurveyTitleStyleProps) =>
      isOQAATFullBleed(themeName, isOQAAT) ? 'relative' : null,
    width: ({ isOQAAT, themeName }: v2SurveyTitleStyleProps) =>
      isOQAATFullBleed(themeName, isOQAAT) ? 'calc(100vw - 80px)' : null,
    left: ({ isOQAAT, themeName }: v2SurveyTitleStyleProps) => (isOQAATFullBleed(themeName, isOQAAT) ? '50%' : null),
    right: ({ isOQAAT, themeName }: v2SurveyTitleStyleProps) => (isOQAATFullBleed(themeName, isOQAAT) ? '50%' : null),
    marginLeft: ({ isOQAAT, themeName }: v2SurveyTitleStyleProps) =>
      isOQAATFullBleed(themeName, isOQAAT) ? '-50vw' : null,
    marginRight: ({ isOQAAT, themeName }: v2SurveyTitleStyleProps) =>
      isOQAATFullBleed(themeName, isOQAAT) ? '-50vw' : null,
  },
});

const Survey: React.FC<SurveyProps> = ({
  pageRequestId,
  showRequestId,
  reqLocale = 'en',
  showWeblinkConfirmationToggle = false,
}) => {
  const survey = useAppSelector(selectSurvey);
  const collector = useAppSelector(selectCollector);
  const currentPageId = useAppSelector(selectCurrentPageId) ?? '';
  const pageIds = useAppSelector(selectPageIds);
  const activePage = useAppSelector(selectActivePage);
  const activePageMeta = activePage?.surveyPage;
  const clientToken = useAppSelector(selectClientToken);
  let questions = useAppSelector(selectQuestions);
  const answers = useAppSelector(selectAnswers);
  const questionsWithAnswersCount = useAppSelector(selectQuestionsWithAnswersCount);
  const surveyOwnerPackageId = useAppSelector(selectSurveyOwnerPackageId);
  const collectorKey = useAppSelector(selectCollectorKey) ?? '';
  const encryptedSmSubject = useAppSelector(selectEncryptedSmSubject);
  const isSurveyVersionDialogVisible = useAppSelector(selectIsDialogVisible);
  const shouldRefreshOnClose = useAppSelector(selectShouldRefreshOnClose);
  const pageIndex: number = pageIds.indexOf(parseInt(currentPageId, 10)) || -1;
  const currentPagePosition = pageIndex + 1 || 1;
  const currentSurveyView = useAppSelector(selectSurveyView);
  const isWhiteLabel = useAppSelector(selectIsWhiteLabel);
  const isCurrentViewCustomThankYou = isThankYouView(currentSurveyView);
  const isCurrentViewSurveyTaking = isSurveyTakingView(currentSurveyView);
  const isCurrentViewCloseWindow = isCloseWindowView(currentSurveyView);
  const dispatch = useAppDispatch();
  const surveyTheme = useSurveyTheme();
  const [hasError, setHasError] = useState(false);
  const { isRTL } = useContext(L10NContext);

  const handleSurveyVersionDialogClose = useCallback(() => {
    dispatch(hideSurveyVersionDialog());
    if (shouldRefreshOnClose) {
      refreshPage();
    }
  }, [dispatch, shouldRefreshOnClose]);

  const layoutContainerRef = React.useRef<HTMLDivElement>(null);
  const persistentProgressRef = React.useRef<HTMLDivElement>(null);
  const partialContainerRef = React.useRef<HTMLDivElement>(null);
  const logoContainerRef = React.useRef<HTMLDivElement>(null);
  const toolbarContainerRef = React.useRef<HTMLDivElement>(null);
  const topContainerRef = React.useRef<HTMLDivElement>(null);
  const mainContainerRef = React.useRef<HTMLDivElement>(null);
  const pageNavigationRef = React.useRef<HTMLDivElement>(null);
  const surveyFooterRef = React.useRef<HTMLDivElement>(null);
  const mainRef = React.useRef<HTMLDivElement>(null);

  /**
   * calculateOffsetBottom
   * Calculate bottom margin for the SurveyFooter. This ensures there is
   * enough room for footer links and last question can move to centre page.
   */
  const [offsetBottom, setOffsetBottom] = useState(0);
  useLayoutEffect(() => {
    const calculateOffsetBottom = (): void => {
      if (survey?.format !== SurveyFormat.OQAAT) return;
      const footerHeight = surveyFooterRef.current?.offsetHeight ?? 0;
      const progressHeight = persistentProgressRef.current?.offsetHeight ?? 0;
      const offset = footerHeight + progressHeight;
      setOffsetBottom(survey?.design?.footer?.enabled ? window.innerHeight / 2 - offset : offset);
    };
    window.addEventListener('resize', calculateOffsetBottom);
    calculateOffsetBottom(); // run immediately
    return () => window.removeEventListener('resize', calculateOffsetBottom);
  }, [survey?.design?.footer?.enabled, survey?.format, surveyFooterRef, persistentProgressRef, setOffsetBottom]);
  useGlobalAfterFocusChangedHandler();

  // Weblink Confirmation Email Checkbox
  const [weblinkConfirmationEmail, setWeblinkConfirmationEmail] = useState('');
  const [weblinkConfirmationEmailChecked, setWeblinkConfirmationEmailChecked] = useState(false);
  const confirmationEmail = showWeblinkConfirmationToggle ? weblinkConfirmationEmail : null;

  // Whether the Done button should be disabled
  const [disableSubmit, setDisableSubmit] = useState(false);

  const dateStarted = React.useRef('');

  // Set StartTime on Load
  useEffect(() => {
    dateStarted.current = new Date().toISOString();
  }, []);

  const [submitSurveyResponses] = useMutation<SubmitSurveyResponseMutation, SubmitSurveyResponseMutationVariables>(
    submitSurveyResponse,
    {
      // uncomment this block after responses api is auth enabled
      context: {
        ...(clientToken && {
          headers: {
            Authorization: `Bearer ${clientToken}`,
            'x-sm-subject': encryptedSmSubject,
          },
        }),
      },
      onCompleted: mutationResult => {
        const hasInvalidResponseId = (mutationResult.submitSurveyResponse?.userErrors ?? []).some(
          userError => userError.code === 'INVALID_RESPONSE_ID'
        );

        if (hasInvalidResponseId) {
          refreshPage();
        } else if (
          mutationResult.submitSurveyResponse &&
          hasInvalidSurveyVersionError(mutationResult.submitSurveyResponse.userErrors)
        ) {
          dispatch(handleSurveyVersionChange());
        } else if (mutationResult?.submitSurveyResponse?.encryptedSMParamforQuizResults) {
          dispatch(setEncrytedSurveyParams(mutationResult.submitSurveyResponse.encryptedSMParamforQuizResults));
        } else if (mutationResult?.submitSurveyResponse?.encryptedSmParam) {
          dispatch(setEncrytedSurveyParams(mutationResult.submitSurveyResponse.encryptedSmParam));
        }
      },
    }
  );

  const _submitSurvey = useCallback(async () => {
    runAfterFocusChangedFn();
    const dateCompleted = new Date().toISOString();
    // disable the Done button while submitting
    setDisableSubmit(true);

    if (showWeblinkConfirmationToggle) {
      await dispatch(
        validateConfirmationEmail({ isChecked: weblinkConfirmationEmailChecked, emailValue: weblinkConfirmationEmail })
      );
    }

    const isValid = await dispatch(validatePage(false)).unwrap();

    if (isCurrentViewSurveyTaking) {
      if (!isValid) {
        dispatch(focusFirstQuestionError());
        setDisableSubmit(false);
        return;
      }

      // to be completed / cleaned up respondentID
      const respondentId = '';

      const response = await dispatch(
        submitResponses({
          mutation: submitSurveyResponses,
          dateStarted: dateStarted.current,
          dateCompleted,
          reqLocale,
          respondentId,
          isPrevious: false,
          isValid,
          isFinalSubmit: true,
          collectorKey,
          confirmationEmail,
        })
      ).unwrap();

      if (!response?.success && response?.userErrors) {
        const hasUnexpectedPageId = response.userErrors.some(e => e.code === 'unexpected_page_id');
        if (hasUnexpectedPageId) {
          refreshPage();
        } else {
          dispatch(setErrors(response.userErrors));
          // re-enable Done button if survey submission fails
          setDisableSubmit(false);
        }
      } else if (!response?.success) {
        setHasError(true);
      } else {
        if (response?.encryptedCookieValue && !collector?.allowMultipleResponses) {
          dispatch(setCookie({ name: `RP_${collector?.id}`, value: response.encryptedCookieValue, expiration: 90 }));
        }
        sendAmplitudeEvent(ANALYTICS_EVENTS.SURVEY_COMPLETE, {
          survey,
          collector,
          surveyOwnerPackageId,
          respondent: response?.respondent,
          questions,
          confirmationEmailCheckboxIsChecked: !!weblinkConfirmationEmail.length,
          isMultilingual: !!survey?.availableSurveyLanguages.length,
        });
        dispatch(surveyComplete({ reqLocale }));
        dispatch(postSurveyCompleteCleanup({ collectorKey }));
      }
    } else {
      dispatch(surveyComplete({ reqLocale }));
    }
  }, [
    dispatch,
    isCurrentViewSurveyTaking,
    submitSurveyResponses,
    reqLocale,
    collectorKey,
    collector,
    survey,
    surveyOwnerPackageId,
    questions,
    showWeblinkConfirmationToggle,
    weblinkConfirmationEmail,
    weblinkConfirmationEmailChecked,
    confirmationEmail,
  ]);

  const [submitPageResponses] = useMutation<SubmitSurveyResponseMutation, SubmitSurveyResponseMutationVariables>(
    submitSurveyResponse,
    {
      context: {
        ...(clientToken && {
          headers: {
            Authorization: `Bearer ${clientToken}`,
            'x-sm-subject': encryptedSmSubject,
          },
        }),
      },
      onCompleted: mutationResult => {
        if (mutationResult.submitSurveyResponse?.respondent) {
          dispatch(
            initializeRespondent({
              id: mutationResult.submitSurveyResponse?.respondent?.id ?? null,
              encryptedInstantResultsSmParam: mutationResult.submitSurveyResponse?.encryptedSMParamforQuizResults ?? '',
            })
          );
        }

        const hasInvalidResponseId = (mutationResult.submitSurveyResponse?.userErrors ?? []).some(
          userError => userError.code === 'INVALID_RESPONSE_ID'
        );
        if (hasInvalidResponseId) {
          return refreshPage();
        }

        const hasInvalidVersion = hasInvalidSurveyVersionError(mutationResult.submitSurveyResponse?.userErrors ?? []);
        if (hasInvalidVersion) {
          return dispatch(handleSurveyVersionChange());
        }

        const surveyPage = mutationResult.submitSurveyResponse?.surveyPage;
        questions = surveyPage?.surveyPageQuestions;
        const pageResponses = answers;
        return dispatch(
          setActivePage({
            activePage: {
              surveyPage: {
                ...surveyPage,
                id: surveyPage?.id ?? '',
                position: surveyPage?.position ?? 0,
                firstQuestionNumber: surveyPage?.firstQuestionNumber ?? 0,
                heading: surveyPage?.heading ?? '',
                subHeading: surveyPage?.subHeading ?? '',
                surveyPageQuestions: {
                  items: surveyPage?.surveyPageQuestions?.items ?? [],
                },
              },
            },
            questions,
            answers: pageResponses,
            clientToken: mutationResult.submitSurveyResponse?.respondent?.clientToken ?? 'Invalid client token.',
            encryptedSmSubject: mutationResult.submitSurveyResponse?.encryptedSmSubject ?? 'Invalid subject token.',
          })
        );
      },
    }
  );

  const _submitPage = useCallback(
    async (isPrevious = false) => {
      runAfterFocusChangedFn();
      const dateCompleted = new Date().toISOString();
      // disable the Navigation buttons
      setDisableSubmit(true);
      const isValid = await dispatch(validatePage(isPrevious))?.unwrap();

      if (isCurrentViewSurveyTaking) {
        if (!isValid && !isPrevious) {
          setDisableSubmit(false);
          // only focus and block progression for next, not prev
          dispatch(focusFirstQuestionError());
          return;
        }

        // ASC to be completed / cleaned up respondentID
        const respondentId = '';

        const response = await dispatch(
          submitResponses({
            mutation: submitPageResponses,
            dateStarted: dateStarted.current,
            dateCompleted,
            reqLocale,
            respondentId,
            isPrevious,
            isValid,
            isFinalSubmit: false,
            collectorKey,
            confirmationEmail,
          })
        )?.unwrap();

        // re-enable Done button if survey submission fails
        setDisableSubmit(false);
        // Shift focus to the top of the page and scroll there
        document.getElementById(`main-content-${survey?.id}`)?.focus();
        window.scrollTo(0, 0);

        if (!response?.success && response?.userErrors) {
          const hasUnexpectedPageId = response.userErrors.some(e => e.code === 'unexpected_page_id');
          if (hasUnexpectedPageId) {
            refreshPage();
          } else {
            dispatch(setErrors(response.userErrors));
            // re-enable Done button if survey submission fails
            setDisableSubmit(false);
          }
        } else if (!response?.success) {
          setHasError(true);
        } else if (response?.encryptedCookieValue && !collector?.allowMultipleResponses) {
          dispatch(setCookie({ name: `RP_${collector?.id}`, value: response.encryptedCookieValue, expiration: 90 }));
        }
      }
    },
    [
      dispatch,
      isCurrentViewSurveyTaking,
      submitPageResponses,
      reqLocale,
      collectorKey,
      survey,
      collector,
      confirmationEmail,
    ]
  );

  const {
    surveyTitleContainer,
    mainContainer,
    layoutMainContainer,
    questionContainer,
    pageFooterContainer,
    sronlyFocus,
    pageDescriptionOkButton,
    pageNavigationContainer,
    surveyFooterContainer,
    classicProgressBarTop,
    customThankyouContainer,
    routerDebugInfo,
  } = useStyles({ offsetBottom });

  const { surveyTitleContainerV2 } = useV2Styles({
    themeName: surveyTheme.name,
    isOQAAT: survey?.format === SurveyFormat.OQAAT,
    backgroundColor: surveyTheme?.surveyTitle?.backgroundColor,
  });

  if (!survey || Object.entries(survey).length === 0) {
    return null;
  }

  const {
    isSurveyTitleEnabled,
    showPageTitles,
    showPageNumbers,
    isFooterEnabled,
    isOQAAT,
    isClassicMode,
    isProgressBarEnabled,
    answerableQuestionCount,
    isQuestionNumberingEnabled,
    showRequiredQuestionAsterisks,
    surveyTitleAlignment,
    labels,
    totalPages,
    logo: { enabled: isLogoEnabled, ...logoProps },
    progressBar: { position: progressBarPosition, showPercent, showPageCount },
    languages,
  } = extrapolateSurveyData(survey);

  const surveyTitleHTML = survey?.titleHTML;
  const surveyId = survey?.id;
  const isFooterShown = !!(isFooterEnabled && !isWhiteLabel);

  /**
   * Page Header
   * calculate proper Page Title and Description based on current page
   */

  const { subHeading: pageDescription = '', heading: pageTitle, position: currentPage = 1 } = activePageMeta ?? {};
  const displayPageTitle = showPageTitles && (!!pageTitle || isV2Theme(surveyTheme.name));
  /**
   * Skip to content will attempt to move the focus position (without actually focusing)
   * to the Survey Title (if exists), then Page title (if exists), finally to the
   * main-content container (<main>).
   * `tabIndex={-1}` is added to each element (backwards compat with <= IE 11)
   */
  const surveyTitleId = `survey-title-${surveyId}`;
  const pageTitleId = `page-title-${surveyId}`;
  const mainContentId = `main-content-${surveyId}`;
  const skipContentId = mainContentId;

  const nextHeadingElement = createHeadingLevel();

  const layoutContainerOptions = {
    layoutContainer: { ref: layoutContainerRef, 'data-survey-format': survey?.format, 'data-page-id': currentPageId },
    partialContainer: { ref: partialContainerRef },
    topContainer: { ref: topContainerRef },
    mainContainer: { className: layoutMainContainer, ref: mainContainerRef },
    logoContainer: { ref: logoContainerRef },
    toolbarContainer: { ref: toolbarContainerRef },
  };

  const settings: RespondentSurveyCapabilityProviderProps['settings'] = {
    survey: {
      numberingEnabled: !!isQuestionNumberingEnabled,
      asteriskEnabled: !!showRequiredQuestionAsterisks,
      okLabel: decodeHtmlEntities(labels.ok) ?? t(COPY.OK_LABEL),
      isClassic: isClassicMode,
    },
    uploadFile: async (file: File) =>
      new Promise<{ id: string; url: string; error?: string }>(resolve => {
        setTimeout(() => {
          console.warn('Hook up to S3 not yet implemented');
          resolve({ id: 'Q001', url: '', error: 'Connection to S3 not yet implemented' });
        }, 250);
      }),
    theme: surveyTheme,
    locale: reqLocale,
  };

  /**
   * @todo revisit signature of `onLeaveQuestion`; externalQuestion argument is
   * not used. (should `questions` be swapped with `externalQuestion` in this case?)
   */
  const upstreamActions: RespondentSurveyCapabilityProviderProps['upstreamActions'] = {
    onLeaveQuestion: (externalQuestion, answer) => {
      if (questions && answer) {
        dispatch(updateAnswer(answer));
      }
    },
  };

  const isLastSurveyPage = (): boolean => {
    return pageIds.length <= 1 || pageIds.length === currentPagePosition;
  };

  if (hasError) {
    return <FiveHundredErrorPage />;
  }

  if (isCurrentViewCustomThankYou && disableSubmit) {
    setDisableSubmit(false);
  }

  const showPrevious = (): boolean => {
    if (isCurrentViewCustomThankYou) {
      return false;
    }
    if (collector?.editResponseType !== 'NEVER') {
      return true;
    }
    return false;
  };

  const handleLanguageSelectClick = (event: React.MouseEvent<HTMLSelectElement>): void => {
    MetricsTracker.track({
      name: USER_EVENTS.ELEMENT_INTERACTION,
      data: {
        amplitudeEvent: ANALYTICS_EVENTS.USER_INTERACTION,
        interactionType: ANALYTICS_INTERACTION_TYPE.CLICK,
        interactionLocation: ANALYTICS_LOCATION.MULTILINGUAL_DROPDOWN,
        surveyId,
        collectorId: collector?.id,
        webType: ANALYTICS_WEBTYPE_TYPE.RESPWEB,
      },
    });
  };

  const handleLanguageSelectChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    MetricsTracker.track({
      name: USER_EVENTS.ELEMENT_INTERACTION,
      data: {
        amplitudeEvent: ANALYTICS_EVENTS.USER_INTERACTION,
        interactionType: ANALYTICS_INTERACTION_TYPE.CHANGE,
        interactionLocation: ANALYTICS_LOCATION.MULTILINGUAL_DROPDOWN,
        surveyId,
        collectorId: collector?.id,
        webType: ANALYTICS_WEBTYPE_TYPE.RESPWEB,
        selectedValue: event.target.value,
      },
    });
  };

  return isCurrentViewCloseWindow ? (
    <></>
  ) : (
    <>
      {isOQAAT && <ActiveViewScrollManager />}
      {!getClientEnvironmentDetails().isMobile && (
        <BypassBlock>
          <a href={`#${skipContentId}`}>
            <T desc={COPY.SKIP_LINK_CONTENT} />
          </a>
        </BypassBlock>
      )}

      {isSurveyVersionDialogVisible === true && (
        <ModifiedSurveyDialog onConfirmClick={handleSurveyVersionDialogClose} />
      )}
      <Layout
        logo={
          !!isLogoEnabled &&
          !(logoProps.verticalAlignment === 'BASELINE' || logoProps.verticalAlignment === 'BOTTOM') && (
            <Logo horizontalAlignment={surveyTitleAlignment} {...logoProps} />
          )
        }
        tools={
          currentPagePosition === 1 && !isCurrentViewCustomThankYou ? (
            <div>
              <LanguageSelect
                selectedLangCode={reqLocale}
                languages={languages}
                onClick={handleLanguageSelectClick}
                onChange={handleLanguageSelectChange}
              />
            </div>
          ) : (
            false
          )
        }
        options={layoutContainerOptions}
        isProgressBarEnabled={isProgressBarEnabled}
        showFooterPrivacyIcon={!isFooterShown}
        isOQAAT={isOQAAT}
      >
        <div
          id={`survey-title-container-${surveyId}`}
          className={classNames(surveyTitleContainer, isV2Theme(surveyTheme.name) ? surveyTitleContainerV2 : {})}
        >
          <SurveyTitle
            id={surveyTitleId}
            align={surveyTitleAlignment}
            element={nextHeadingElement(0)}
            text={surveyTitleHTML ?? ''}
            showTitleText={!!isSurveyTitleEnabled}
            logo={
              (!!isLogoEnabled &&
                (logoProps.verticalAlignment === 'BASELINE' || logoProps.verticalAlignment === 'BOTTOM') && {
                  logo: <Logo horizontalAlignment={surveyTitleAlignment} {...logoProps} />,
                  verticalAlignment: logoProps.verticalAlignment,
                }) ||
              undefined
            }
          />
          {/* <SurveyIntro text={surveyIntro} /> */}
          {/* <SurveyButton translate="no" size="md">OK</SurveyButton> */}
        </div>
        <main id={mainContentId} ref={mainRef} className={mainContainer}>
          {isCurrentViewCustomThankYou && (
            <div
              id={`custom-thank-you-container-${surveyId}`}
              className={customThankyouContainer}
              dangerouslySetInnerHTML={{ __html: sanitizeString(collector?.thankYouPage?.message ?? '') }}
            />
          )}
          {isCurrentViewSurveyTaking && (
            <>
              <div id={`page-header-container-${surveyId}`}>
                {displayPageTitle && (
                  <PageTitle
                    id={pageTitleId}
                    element={nextHeadingElement(1)}
                    text={pageTitle ?? ''}
                    numbered={showPageNumbers}
                    number={currentPage}
                    isOQAAT={isOQAAT}
                  />
                )}
                {!!pageDescription && (
                  <SurveyFormatView name="pageDescription" noShield>
                    {(isActive, setNextActive) => (
                      <>
                        <PageDescription text={pageDescription} />
                        {isOQAAT && (
                          <SurveyButton
                            size="md"
                            translate="no"
                            onClick={setNextActive}
                            className={classNames(pageDescriptionOkButton, { [sronlyFocus]: !isActive })}
                          >
                            {labels.ok || t(COPY.OK_LABEL)}
                          </SurveyButton>
                        )}
                      </>
                    )}
                  </SurveyFormatView>
                )}

                {isProgressBarEnabled && isClassicMode && progressBarPosition === 'TOP' && (
                  <div className={classicProgressBarTop}>
                    <ClassicProgressBar
                      value={currentPage}
                      total={totalPages ?? 1}
                      showPageCount={showPageCount ?? true}
                      showPercentage={showPercent ?? true}
                      isRTL={isRTL}
                    />
                  </div>
                )}
              </div>

              <RespondentSurveyCapabilityProvider settings={settings} upstreamActions={upstreamActions}>
                <form id={`form-${surveyId}`} className={questionContainer}>
                  {/**
                   * NOTE: active (boolean) will be controlled by Progression Engine
                   * NOTE: Are all the nullified fields (questionBank, validation) needed?
                   */}
                  <QuestionList
                    questionNumberType={survey.design?.questionNumbering?.type}
                    questions={questions?.items as ExternalQuestion[]}
                    answers={answers}
                    isOQAAT={isOQAAT}
                  />
                  {showWeblinkConfirmationToggle && isLastSurveyPage() && (
                    <div id="weblink-confirmation-checkbox">
                      <WeblinkConfirmationCheckbox
                        onBlur={setWeblinkConfirmationEmail}
                        onToggle={setWeblinkConfirmationEmailChecked}
                      />
                    </div>
                  )}
                  {/**
                   *  When survey is in OQAAT mode, progress bar is below the survey footer and is sticky
                   */}
                  {isProgressBarEnabled && isOQAAT && (
                    <PersistentProgressBar
                      value={questionsWithAnswersCount}
                      total={answerableQuestionCount}
                      ref={persistentProgressRef}
                      isRTL={isRTL}
                    />
                  )}
                  {isProgressBarEnabled && isClassicMode && progressBarPosition === 'BOTTOM' && (
                    <ClassicProgressBar
                      value={currentPage}
                      total={totalPages ?? 1}
                      showPageCount={showPageCount ?? true}
                      showPercentage={showPercent ?? true}
                      isRTL={isRTL}
                    />
                  )}
                </form>
              </RespondentSurveyCapabilityProvider>
            </>
          )}
          <footer id={`page-footer-container-${surveyId}`} className={pageFooterContainer}>
            {pageIds.length > 1 && (
              <SurveyFormatView name="pageNavigation" noShield autoPadding={false}>
                <PageNavigation
                  ref={pageNavigationRef}
                  className={pageNavigationContainer}
                  labels={labels}
                  currentPage={currentPagePosition}
                  totalPages={pageIds?.length || 1}
                  onNext={() => {
                    void _submitPage();
                  }}
                  onPrevious={() => {
                    void _submitPage(true);
                  }}
                  onDone={() => {
                    void _submitSurvey();
                  }}
                  disableSubmit={disableSubmit}
                  showPrevious={showPrevious()}
                />
              </SurveyFormatView>
            )}
            {pageIds.length <= 1 && (
              <SurveyFormatView name="pageNavigation" noShield autoPadding={false}>
                <PageNavigation
                  ref={pageNavigationRef}
                  className={pageNavigationContainer}
                  labels={labels}
                  currentPage={currentPagePosition}
                  totalPages={pageIds?.length || 1}
                  onDone={() => {
                    void _submitSurvey();
                  }}
                  disableSubmit={disableSubmit}
                />
              </SurveyFormatView>
            )}
          </footer>
        </main>
        <footer id={`survey-footer-container-${surveyId}`} className={surveyFooterContainer} ref={surveyFooterRef}>
          {isCurrentViewSurveyTaking && isFooterShown && (
            <SurveyFooter
              element="div"
              isGrayScale={!!survey?.design?.footer?.grayscale}
              showPrivacyCookiePolicy={currentPage === 1}
              // Below are placeholders, fill in when we get info from query
              showReportProblem={false}
              reportProblemURL=""
              isGoogleSubdomain={false}
              googlePrivacyPolicyURL=""
              surveyId={surveyId}
              collectorId={collector?.id}
              collectorType={collector?.type}
            />
          )}
          {showRequestId && (
            <span className={routerDebugInfo} aria-hidden="true">
              respweb:{pageRequestId}
            </span>
          )}
        </footer>
      </Layout>
    </>
  );
};

export default Survey;
