import React, { ReactElement } from 'react';
import { t, defineMessages } from '@sm/intl';
import { ToggleableEmailField } from '@sm/question-ui/respondent-survey';
import { isValidEmail } from '@sm/utils';

import { addError, selectErrorsById, removeErrorsById } from '~app/pages/SurveyTaking/v2/slices/errorsSlice';
import { useAppDispatchV2 as useAppDispatch, useAppSelectorV2 as useAppSelector } from '~app/hooks';
import { ErrorType } from '~app/pages/SurveyTaking/errors';
import WEBLINK_ID from '~app/components/constants';

export const COPY = defineMessages({
  EMAIL_ERROR: {
    id: 'Survey.WeblinkConfirmationCheckbox.emailError',
    defaultMessage: 'Please enter a valid email address.',
    description:
      '[Type: message][Vis: medium] - Error message displayed when an invalid email address is entered in the weblink confirmation text input.',
  },
});
type ResponseType = {
  value: string;
  isDirty: boolean;
};

type Props = {
  onBlur: (response: string) => void;
  onToggle?: (checked: boolean) => void;
};

function WeblinkConfirmationCheckbox({ onBlur, onToggle }: Props): ReactElement {
  const dispatch = useAppDispatch();
  const errors = useAppSelector(state => selectErrorsById(state, WEBLINK_ID));

  const handleToggle = (checked: boolean): void => {
    if (!checked) {
      dispatch(removeErrorsById(WEBLINK_ID));
    }
    onToggle?.(checked);
  };

  const onEmailFieldBlur = (response: ResponseType): void => {
    if (!response.isDirty) {
      return;
    }

    if (response.value === '') {
      if (!errors.some(e => e.questionId === WEBLINK_ID)) {
        dispatch(
          addError({
            code: ErrorType.MISSING_REQUIRED_FIELD,
            detail: t(COPY.EMAIL_ERROR),
            questionId: WEBLINK_ID,
            field: null,
          })
        );
      }
    } else if (!isValidEmail(response.value)) {
      if (!errors.some(e => e.questionId === WEBLINK_ID)) {
        dispatch(
          addError({
            code: ErrorType.INVALID_OPEN_ENDED_VALIDATION,
            detail: t(COPY.EMAIL_ERROR),
            questionId: WEBLINK_ID,
            field: null,
          })
        );
      }
    } else {
      dispatch(removeErrorsById(WEBLINK_ID));
      onBlur(response.value);
    }
  };

  const error = errors && errors.length > 0 ? errors[errors.length - 1].detail : '';
  return (
    <ToggleableEmailField
      responses={[]}
      onBlur={onEmailFieldBlur}
      error={error}
      onToggle={handleToggle}
      id={WEBLINK_ID}
    />
  );
}

export default WeblinkConfirmationCheckbox;
