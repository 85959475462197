/**
 * @todo Add @sm/question-definitions as an explicit dependency; or, import
 * from explicit dependency source. i.e. @sm/question-widgets
 *
 * As a best-practice, dependencies of a dependency should not be imported.
 */
import { QuestionError } from '@sm/question-definitions';
import { ExternalValidator } from '@sm/question-widgets/respondent-survey';

/** START: Validation Manager */
export type ValidationMap = Record<string, ExternalValidator[]>;
export type ValidationResult = { isValid: boolean; errors: QuestionError[] };
/** END: Validation Manager */

// copied from: smpackages/packages/utils/src/object/deepFreeze.ts
export type DeepReadOnly<T> = { readonly [P in keyof T]: DeepReadOnly<T[P]> };

export type ValidationStatuses = 'ok' | 'error';

export const ValidationStatus = {
  OK: 'ok' as ValidationStatuses,
  Error: 'error' as ValidationStatuses,
};

export type ValidationMessageTypeSeverity = 'info' | 'warn' | 'error';

export type ValidationMessageType = Pick<QuestionError, 'questionId' | 'detail'> & {
  source: 'local';
  type: ValidationMessageTypeSeverity;
  field: string | null;
};

export type AnswerValidationResultType = {
  status: ValidationStatuses;
  messages?: DeepReadOnly<ValidationMessageType[]> | null;
  isValid: () => boolean;
  getFirstMessage: () => ValidationMessageType | undefined;
};

export type ValidationResultTuple = [boolean, ValidationMessageType[] | null];

export type QuestionValidator = (forceValidation?: boolean, value?: string) => QuestionError[] | null;

export type QuestionValidators = {
  validators: QuestionValidator[];
  lastResult?: ValidationResultTuple | null;
};

export type QuestionsValidators = {
  [key: string]: QuestionValidators;
};
