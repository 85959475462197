import React, { useContext, useMemo } from 'react';
import classNames from 'classnames';
import { createUseStyles } from 'react-jss';
import { useSurveyTheme } from '@sm/webassets/SurveyTheme/context';
import { L10NContext } from '@sm/intl';
import { getThemeLayoutType, isV2Theme, isCenter, isOQAATFullBleed, LayoutName } from '~app/helpers/themeLayout';
import useStyles from './useStyles';
import FooterPrivacyIcon from '~app/components/Survey/FooterPrivacyIcon';

export type LayoutContainerName =
  /** Outer/Wrapping container */
  | 'layoutContainer'
  /**  Left/Right/Top Spacing/Image container */
  | 'partialContainer'
  /** Outer Content container */
  | 'contentContainer'
  /**  */
  | 'topContainer'
  /** Inner Content Container */
  | 'mainContainer'
  /** Logo container (moves between Partial <=> Content) */
  | 'logoContainer'
  /** Toolbar container (Exit & Locale, etc) */
  | 'toolbarContainer'
  /** Privacy Icon container when no footer is enabled */
  | 'footerPrivacyIconContainer';

/** Allows a string (ID) or any HTMLDivElement props with React.Ref */
export type ContainerProps = React.HTMLAttributes<HTMLDivElement> & { ref?: React.RefObject<HTMLDivElement> };

export type LayoutContainerOptions = Record<LayoutContainerName, ContainerProps>;

export type Props = React.PropsWithChildren<{
  /** An element to show in the logo container */
  logo?: React.ReactElement | false;
  /** Element(s) to show in the toolbar container */
  tools?: React.ReactElement | false;
  /** Container Options (props) */
  options: Partial<LayoutContainerOptions>;
  showFooterPrivacyIcon?: boolean;
  isOQAAT?: boolean;
  isProgressBarEnabled?: boolean;
}>;

const defaultContainerProps: LayoutContainerOptions = {
  layoutContainer: { id: 'survey-layout-container' },
  partialContainer: { id: 'survey-layout-partial-container' },
  contentContainer: { id: 'survey-layout-content-container' },
  topContainer: { id: 'survey-layout-top-container' },
  mainContainer: { id: 'survey-main-content' },
  logoContainer: { id: 'survey-logo-container' },
  toolbarContainer: { id: 'survey-toolbar-container' },
  footerPrivacyIconContainer: { id: 'privacy-icon-lock' },
};

export type v2LayoutStyleProps = {
  themeName: string;
  isOQAAT: boolean;
  layoutName: LayoutName;
  isBanner?: boolean;
  logoBackgroundColor?: string;
  primaryBackgroundColor?: string;
  hasBackgroundImage?: boolean;
};

const useV2Styles = createUseStyles((v2LayoutStyle: v2LayoutStyleProps) => {
  return {
    layoutTopContainerV2: {
      margin: ({ layoutName, isBanner }: v2LayoutStyleProps) => (isCenter(layoutName) || isBanner ? '0 auto' : {}),
      backgroundColor: ({ logoBackgroundColor }: v2LayoutStyleProps) => logoBackgroundColor,
      position: ({ isOQAAT, themeName }: v2LayoutStyleProps) =>
        isOQAATFullBleed(themeName, isOQAAT) ? 'relative' : null,
      width: ({ isOQAAT, themeName }: v2LayoutStyleProps) => (isOQAATFullBleed(themeName, isOQAAT) ? '100vw' : null),
      left: ({ isOQAAT, themeName }: v2LayoutStyleProps) => (isOQAATFullBleed(themeName, isOQAAT) ? '50%' : null),
      right: ({ isOQAAT, themeName }: v2LayoutStyleProps) => (isOQAATFullBleed(themeName, isOQAAT) ? '50%' : null),
      maxWidth: ({ isOQAAT, themeName }: v2LayoutStyleProps) =>
        isOQAATFullBleed(themeName, isOQAAT) ? '100%' : '896px',
      marginLeft: ({ isOQAAT, themeName }: v2LayoutStyleProps) =>
        isOQAATFullBleed(themeName, isOQAAT) ? '-50%' : null,
      marginRight: ({ isOQAAT, themeName }: v2LayoutStyleProps) =>
        isOQAATFullBleed(themeName, isOQAAT) ? '-50%' : null,
    },
    layoutContentContainerV2: {
      overflowX: ({ isOQAAT, themeName }: v2LayoutStyleProps) =>
        isOQAATFullBleed(themeName, isOQAAT) ? 'hidden' : 'visible',
    },
    layoutPartialContainerV2: {
      // for OQAAT format, we override the survey background color to match the page background so it looks like
      // it's full bleed when there's no background image (for example, for aqua)
      '&:before': {
        background: ({ isOQAAT, hasBackgroundImage }: v2LayoutStyleProps) =>
          isOQAAT && !hasBackgroundImage ? 'none' : null,
        backgroundColor: ({ isOQAAT, primaryBackgroundColor, hasBackgroundImage }: v2LayoutStyleProps) =>
          isOQAAT && !hasBackgroundImage ? primaryBackgroundColor : null,
      },
    },
  };
});

function Layout({
  options: containerOptions = {},
  logo: Logo,
  tools: Tools,
  showFooterPrivacyIcon,
  isOQAAT = true,
  isProgressBarEnabled,
  children,
}: Props): React.ReactElement {
  const { layout: layoutName, name: themeName, logoStyle, primaryBackgroundColor, surveyPage } = useSurveyTheme();
  const { isBanner } = getThemeLayoutType(layoutName);
  const { isRTL } = useContext(L10NContext);

  const isToolbarEnabled = !!Tools;
  const isLogoEnabled = !!Logo;

  const {
    layoutContainer,
    layoutLogoContainer,
    layoutPartialContainer,
    layoutToolbarContainer,
    layoutContentContainer,
    layoutTopContainer,
    layoutMainContainer,
    footerPrivacyIconContainer,
  } = useStyles({ isRTL });

  const { layoutContentContainerV2, layoutTopContainerV2, layoutPartialContainerV2 } = useV2Styles({
    themeName,
    layoutName,
    isOQAAT,
    isBanner,
    logoBackgroundColor: logoStyle?.backgroundColor,
    primaryBackgroundColor,
    hasBackgroundImage: !!surveyPage?.backgroundImage,
  });

  const {
    layoutContainer: { className: layoutContainerClasses, ...layoutContainerProps },
    partialContainer: { className: partialContainerClasses, ...partialContainerProps },
    contentContainer: { className: contentContainerClasses, ...contentContainerProps },
    topContainer: { className: topContainerClasses, ...topContainerProps },
    mainContainer: { className: mainContainerClasses, ...mainContainerProps },
    toolbarContainer: { className: toolbarContainerClasses, ...toolbarContainerProps },
    logoContainer: { className: logoContainerClasses, ...logoContainerProps },
  } = useMemo(() => {
    return Object.keys(containerOptions).reduce((result: LayoutContainerOptions, key: string) => {
      return { ...result, [key]: containerOptions[key as LayoutContainerName] ?? {} };
    }, defaultContainerProps);
  }, [containerOptions]);

  return (
    <div className={classNames(layoutContainer, layoutContainerClasses)} {...layoutContainerProps}>
      <div
        className={classNames(
          layoutPartialContainer,
          isV2Theme(themeName) ? layoutPartialContainerV2 : {},
          partialContainerClasses
        )}
        {...partialContainerProps}
      >
        {isBanner && isLogoEnabled && (
          <div className={classNames(layoutLogoContainer, logoContainerClasses)} {...logoContainerProps}>
            {Logo}
          </div>
        )}
      </div>
      <div
        className={classNames(
          layoutContentContainer,
          isV2Theme(themeName) ? layoutContentContainerV2 : {},
          contentContainerClasses
        )}
        {...contentContainerProps}
      >
        <div
          className={classNames(
            layoutTopContainer,
            isV2Theme(themeName) ? layoutTopContainerV2 : {},
            topContainerClasses
          )}
          {...topContainerProps}
        >
          {isToolbarEnabled && (
            <div className={classNames(layoutToolbarContainer, toolbarContainerClasses)} {...toolbarContainerProps}>
              {Tools}
            </div>
          )}
          {!isBanner && isLogoEnabled && (
            <div className={classNames(layoutLogoContainer, logoContainerClasses)} {...logoContainerProps}>
              {Logo}
            </div>
          )}
        </div>
        <div
          className={classNames(layoutMainContainer, mainContainerClasses)}
          suppressHydrationWarning // styles can be set by js in pages/_document.page.tsx
          {...mainContainerProps}
        >
          {children}
        </div>
        {showFooterPrivacyIcon && (
          <div className={footerPrivacyIconContainer}>
            <FooterPrivacyIcon needsBottomOffset={!!(isOQAAT && isProgressBarEnabled)} />
          </div>
        )}
      </div>
    </div>
  );
}

export default Layout;
