import { MasterL10nMessages } from '@sm/question-ui/respondent-survey';
import { defineMessages } from '@sm/intl';

/**
 * Copy used by SMQuestions - re-declaring the messages here allows translation into the
 * 56 languages needed by RespWeb (as opposed to the 16 in SMQuestions)
 */

export const COPY: MasterL10nMessages = defineMessages({
  NPS_QUESTION_TITLE: {
    id: 'Nps.QUESTION_TITLE',
    defaultMessage:
      '<div class="{srOnlyClass}">On a scale of {minValue} to {maxValue}, </div>{heading}<div id="{instructionsId}" class="{srOnlyClass}"> {minValue} for {minLabelText}, {maxValue} for {maxLabelText}</div>',
    description: '[Type: header][Vis: high] - question title',
  },
  OK_LABEL: {
    id: 'Button.OK_LABEL',
    defaultMessage: 'OK',
    description: '[Type: button][Vis: high] - question ok label',
  },
  PREVIOUS_MONTH: {
    id: 'CalendarPicker.previousMonth',
    defaultMessage: 'Previous Month',
    description: '[Type: button][Vis: high] - button label to go to previous month',
  },
  NEXT_MONTH: {
    id: 'CalendarPicker.nextMonth',
    defaultMessage: 'Next Month',
    description: '[Type: button][Vis: high] - button label to go to next month',
  },
  MONTH_SELECT: {
    id: 'CalendarPicker.month',
    defaultMessage: 'Select a month',
    description: '[Type: label][Vis: high] - label for month selection combo box',
  },
  YEAR_SELECT: {
    id: 'CalendarPicker.year',
    defaultMessage: 'Select a year',
    description: '[Type: label][Vis: high] - label for year selection combo box',
  },
  MONTHS: {
    id: 'CalendarPicker.months',
    defaultMessage:
      '{months, select, January {January} February {February} March {March} April {April} May {May} June {June} July {July} August {August} September {September} October {October} November {November} December {December} other {other}}',
    description: '[Type: label][Vis: high] - label for the months',
  },
  DAY_OF_WEEK_LEGEND_SHORT: {
    id: 'Month.dayOfWeekLegendShort',
    defaultMessage: '{dayOfTheWeek, select, Mo {Mo} Tu {Tu} We {We} Th {Th} Fr {Fr} Sa {Sa} Su {Su} other {other}}',
    description: '[Type: label][Vis: high] - label for the day of the week in shorthand',
  },
  DAY_OF_WEEK_LEGEND_FULL: {
    id: 'Month.dayOfWeekLegendLong',
    defaultMessage:
      '{dayOfTheWeek, select, Mo {Monday} Tu {Tuesday} We {Wednesday} Th {Thursday} Fr {Friday} Sa {Saturday} Su {Sunday} other {other}}',
    description: '[Type: label][Vis: high] - label for the day of the week',
  },
  DAY_OF_MONTH: {
    id: 'Day.dayOfMonth',
    defaultMessage: '{dayInCalendar, date, full}',
    description: '[Type: label][Vis: high] - label for the full date',
  },
  DATE_LABEL: {
    id: 'DateInputWrapper.dateLabel',
    defaultMessage: 'Date',
    description: '[Type: label][Vis: high] - label for date input',
  },
  DATE_BUTTON_LABEL: {
    id: 'DateInputWrapper.dateButton',
    defaultMessage: 'Open calendar',
    description: '[Type: button][Vis: high] - aria text for opening the calendar picker',
  },
  US_DATE_FORMAT: {
    id: 'DateInputWrapper.dateFormatUs',
    defaultMessage: 'MM/DD/YYYY',
    description:
      '[Type: label][Vis: high] - ISO 8601 US date character format notation. No localized delimiter translation. Must be uppercase.',
  },
  INTL_DATE_FORMAT: {
    id: 'DateInputWrapper.dateFormatIntl',
    defaultMessage: 'DD/MM/YYYY',
    description: '[Type: label][Vis: high] - ISO 8601 International date character format notation. Must be uppercase.',
  },
  TIME_LABEL: {
    id: 'TimeInputWrapper.timeLabel',
    defaultMessage: 'Time',
    description: '[Type: label][Vis: high] - label for time input',
  },
  HOUR_LABEL: {
    id: 'TimeInputWrapper.hour',
    defaultMessage: 'Hour',
    description: '[Type: label][Vis: high] - label for hour input',
  },
  MINUTES_LABEL: {
    id: 'TimeInputWrapper.minutes',
    defaultMessage: 'Minutes',
    description: '[Type: label][Vis: high] - label for minutes input',
  },
  DAYTIME_LABEL: {
    id: 'TimeInputWrapper.daytimeLabel',
    defaultMessage: 'AM/PM',
    description: '[Type: label][Vis: high] - label for daytime AM/PM select',
  },
  AM: {
    id: 'TimeInputWrapper.am',
    defaultMessage: 'AM',
    description: '[Type: label][Vis: high] - label for ante meridiem',
  },
  PM: {
    id: 'TimeInputWrapper.pm',
    defaultMessage: 'PM',
    description: '[Type: label][Vis: high] - label for post meridiem',
  },
  HOUR_PLACEHOLDER: {
    id: 'TimeInputWrapper.hhPlaceholder',
    defaultMessage: 'hh',
    description: '[Type: placeholder][Vis: high] - placeholder text for hour input',
  },
  MINUTE_PLACEHOLDER: {
    id: 'TimeInputWrapper.mmPlaceholder',
    defaultMessage: 'mm',
    description: '[Type: placeholder][Vis: high] - placeholder text for minutes input',
  },
  DATE_INTL_FORMAT_ERROR: {
    id: 'DateTime.formatErrorDateIntl',
    defaultMessage: 'Please provide date in DD/MM/YYYY format.',
    description:
      '[Type: label][Vis: high] - label for incorrect or missing date error (using ISO 8601 US date character format notation)',
  },
  DATE_US_FORMAT_ERROR: {
    id: 'DateTime.formatErrorDateUs',
    defaultMessage: 'Please provide date in MM/DD/YYYY format.',
    description:
      '[Type: label][Vis: high] - label for incorrect or missing date error (using ISO 8601 international date character format notation)',
  },
  SELECT_AN_OPTION: {
    id: 'MatrixDropdown.EmptyTopNativeSelect',
    defaultMessage: 'Select an option',
    description: 'Label for screen readers when the top/default selection is empty',
  },
  ERROR_ENTER_HOURS: {
    id: 'DateTime.errorEnterHours',
    defaultMessage: 'Please enter hours from 0 to 23.',
    description: '[Type: label][Vis: high] - label for incorrect or missing hour input',
  },
  ERROR_ENTER_MINUTES: {
    id: 'DateTime.errorEnterMinutes',
    defaultMessage: 'Please enter minutes from 0 to 59.',
    description: '[Type: label][Vis: high] - label for incorrect or missing minute input',
  },
  ERROR_SELECT_PERIOD: {
    id: 'DateTime.errorSelectPeriod',
    defaultMessage: 'Please select AM/PM.',
    description: '[Type: label][Vis: high] - label for incorrect or missing period selection',
  },
  ERROR_YEAR_OUT_OF_RANGE: {
    id: 'DateTime.errorYearOutOfRange',
    defaultMessage: 'Please provide year from 1900 to 9999.',
    description: '[Type: label][Vis: high] - label for year out of range',
  },
  ARIA_RANK_MOVED_UP: {
    id: 'Ranking.AriaRankMovedUp',
    defaultMessage: '{label} moved up to number {position}',
    description: '[Type: button][Vis: high] - accessible ranking moved up label',
  },
  ARIA_RANK_MOVED_DOWN: {
    id: 'Ranking.AriaRankMovedDown',
    defaultMessage: '{label} moved down to number {position}',
    description: '[Type: button][Vis: high] - accessible ranking moved down label',
  },
  ARIA_RANK_MOVED_TO_TOP: {
    id: 'Ranking.AriaRankAtTop',
    defaultMessage: '{label} moved to the top of the list',
    description: '[Type: button][Vis: high] - accessible ranking at top of list',
  },
  ARIA_RANK_MOVED_TO_BOTTOM: {
    id: 'Ranking.AriaRankAtBottom',
    defaultMessage: '{label} moved to the bottom of the list',
    description: '[Type: button][Vis: high] - accessible ranking at bottom of list',
  },
  SLIDER_CLEAR_BUTTON_LABEL: {
    id: 'Slider.CLEAR_BUTTON_LABEL',
    defaultMessage: 'Clear',
    description: '[Type: label][Vis: low] - screen reader only label for clear button',
  },
  SLIDER_STEP_MESSAGE: {
    id: 'Slider.STEP_MESSAGE',
    defaultMessage: "We adjusted the number you entered based on the slider's scale.",
    description:
      '[Type: message][Vis: medium] - Message displayed when the entered value needs to be moved to the next closest step',
  },
  REQUIRED: {
    id: 'RespondentQuestionTitle.REQUIRED',
    defaultMessage: '(Required.)',
    description: '[Type: label][Vis: medium] - visualy hidden required text',
  },
  CHOOSE_OR_DRAG: {
    id: 'FileUpload.chooseDragFile',
    defaultMessage: '<div class={chooseClass}>Choose a file</div> <div class={dragClass}>or drag it here</div>',
    description: '[Type: label][Vis: high] - label for file input when a file has not been uploaded',
  },
  REPLACE_OR_DRAG: {
    id: 'FileUpload.replaceDragFile',
    defaultMessage: '<div class={chooseClass}>Replace file</div> <div class={dragClass}>or drag it here</div>',
    description: '[Type: label][Vis: high] - label for file input when a file has been uploaded',
  },
  SUPPORTED_FILES: {
    id: 'FileUpload.supportedFiles',
    defaultMessage: 'Supported Files',
    description: '[Type: label][Vis: high] - Prefix to the supported files list',
  },
  FILE_UPLOADING: {
    id: 'FileUpload.fileUploading',
    defaultMessage: 'File uploading',
    description: '[Type: label][Vis: high] - Label for when a file is uploading',
  },
  REMOVE_FILE: {
    id: 'FileUpload.removeFile',
    defaultMessage: 'Remove file',
    description: '[Type: label][Vis: high] - Label for the remove currently selected file button',
  },
  FILE_UPLOADED: {
    id: 'FileUpload.fileUploaded',
    defaultMessage: 'File uploaded successfully',
    description: '[Type: label][Vis: high] - Announcement message for when a file is uploaded successfully',
  },
  STAR: {
    id: 'StarRating.STAR',
    defaultMessage: '{count} {count, plural, one {star} other {stars}}',
    description: 'Star Symbol Rating Label',
  },
  SMILEY: {
    id: 'StarRating.Smiley',
    defaultMessage: '{count} {count, plural, one {smiley} other {smileys}}',
    description: 'Smiley Symbol Rating Label',
  },
  HEART: {
    id: 'StarRating.Heart',
    defaultMessage: '{count} {count, plural, one {heart} other {hearts}}',
    description: 'Heart Symbol Rating Label',
  },
  THUMB: {
    id: 'StarRating.Thumb',
    defaultMessage: '{count} {count, plural, one {thumb} other {thumbs}}',
    description: 'Thumb Symbol Rating Label',
  },
  COUNTRY_AF: {
    id: 'countriesL10nMessages.AF',
    defaultMessage: 'Afghanistan',
    description: '[Type: label][Vis: high] - country label for Afghanistan',
  },
  COUNTRY_AX: {
    id: 'countriesL10nMessages.AX',
    defaultMessage: 'Åland Islands',
    description: '[Type: label][Vis: high] - country label for Åland Islands',
  },
  COUNTRY_AL: {
    id: 'countriesL10nMessages.AL',
    defaultMessage: 'Albania',
    description: '[Type: label][Vis: high] - country label for Albania',
  },
  COUNTRY_DZ: {
    id: 'countriesL10nMessages.DZ',
    defaultMessage: 'Algeria',
    description: '[Type: label][Vis: high] - country label for Algeria',
  },
  COUNTRY_AD: {
    id: 'countriesL10nMessages.AD',
    defaultMessage: 'Andorra',
    description: '[Type: label][Vis: high] - country label for Andorra',
  },
  COUNTRY_AO: {
    id: 'countriesL10nMessages.AO',
    defaultMessage: 'Angola',
    description: '[Type: label][Vis: high] - country label for Angola',
  },
  COUNTRY_AI: {
    id: 'countriesL10nMessages.AI',
    defaultMessage: 'Anguilla',
    description: '[Type: label][Vis: high] - country label for Anguilla',
  },
  COUNTRY_AS: {
    id: 'countriesL10nMessages.AS',
    defaultMessage: 'American Samoa',
    description: '[Type: label][Vis: high] - country label for American Samoa',
  },
  COUNTRY_AG: {
    id: 'countriesL10nMessages.AG',
    defaultMessage: 'Antigua and Barbuda',
    description: '[Type: label][Vis: high] - country label for Antigua and Barbuda',
  },
  COUNTRY_AR: {
    id: 'countriesL10nMessages.AR',
    defaultMessage: 'Argentina',
    description: '[Type: label][Vis: high] - country label for Argentina',
  },
  COUNTRY_AM: {
    id: 'countriesL10nMessages.AM',
    defaultMessage: 'Armenia',
    description: '[Type: label][Vis: high] - country label for Armenia',
  },
  COUNTRY_AC: {
    id: 'countriesL10nMessages.AC',
    defaultMessage: 'Ascension Island',
    description: '[Type: label][Vis: high] - country label for Ascension Island',
  },
  COUNTRY_AW: {
    id: 'countriesL10nMessages.AW',
    defaultMessage: 'Aruba',
    description: '[Type: label][Vis: high] - country label for Aruba',
  },
  COUNTRY_AU: {
    id: 'countriesL10nMessages.AU',
    defaultMessage: 'Australia',
    description: '[Type: label][Vis: high] - country label for Australia',
  },
  COUNTRY_AT: {
    id: 'countriesL10nMessages.AT',
    defaultMessage: 'Austria',
    description: '[Type: label][Vis: high] - country label for Austria',
  },
  COUNTRY_AZ: {
    id: 'countriesL10nMessages.AZ',
    defaultMessage: 'Azerbaijan',
    description: '[Type: label][Vis: high] - country label for Azerbaijan',
  },
  COUNTRY_BS: {
    id: 'countriesL10nMessages.BS',
    defaultMessage: 'Bahamas',
    description: '[Type: label][Vis: high] - country label for Bahamas',
  },
  COUNTRY_BH: {
    id: 'countriesL10nMessages.BH',
    defaultMessage: 'Bahrain',
    description: '[Type: label][Vis: high] - country label for Bahrain',
  },
  COUNTRY_BD: {
    id: 'countriesL10nMessages.BD',
    defaultMessage: 'Bangladesh',
    description: '[Type: label][Vis: high] - country label for Bangladesh',
  },
  COUNTRY_BB: {
    id: 'countriesL10nMessages.BB',
    defaultMessage: 'Barbados',
    description: '[Type: label][Vis: high] - country label for Barbados',
  },
  COUNTRY_BE: {
    id: 'countriesL10nMessages.BE',
    defaultMessage: 'Belgium',
    description: '[Type: label][Vis: high] - country label for Belgium',
  },
  COUNTRY_BZ: {
    id: 'countriesL10nMessages.BZ',
    defaultMessage: 'Belize',
    description: '[Type: label][Vis: high] - country label for Belize',
  },
  COUNTRY_BJ: {
    id: 'countriesL10nMessages.BJ',
    defaultMessage: 'Benin',
    description: '[Type: label][Vis: high] - country label for Benin',
  },
  COUNTRY_BM: {
    id: 'countriesL10nMessages.BM',
    defaultMessage: 'Bermuda',
    description: '[Type: label][Vis: high] - country label for Bermuda',
  },
  COUNTRY_BT: {
    id: 'countriesL10nMessages.BT',
    defaultMessage: 'Bhutan',
    description: '[Type: label][Vis: high] - country label for Bhutan',
  },
  COUNTRY_BO: {
    id: 'countriesL10nMessages.BO',
    defaultMessage: 'Bolivia',
    description: '[Type: label][Vis: high] - country label for Bolivia, Plurinational State of',
  },
  COUNTRY_BQ: {
    id: 'countriesL10nMessages.BQ',
    defaultMessage: 'Bonaire, Sint Eustatius and Saba',
    description: '[Type: label][Vis: high] - country label for Bonaire, Sint Eustatius and Saba',
  },
  COUNTRY_BA: {
    id: 'countriesL10nMessages.BA',
    defaultMessage: 'Bosnia and Herzegovina',
    description: '[Type: label][Vis: high] - country label for Bosnia and Herzegovina',
  },
  COUNTRY_BW: {
    id: 'countriesL10nMessages.BW',
    defaultMessage: 'Botswana',
    description: '[Type: label][Vis: high] - country label for Botswana',
  },
  COUNTRY_BR: {
    id: 'countriesL10nMessages.BR',
    defaultMessage: 'Brazil',
    description: '[Type: label][Vis: high] - country label for Brazil',
  },
  COUNTRY_IO: {
    id: 'countriesL10nMessages.IO',
    defaultMessage: 'British Indian Ocean Territory',
    description: '[Type: label][Vis: high] - country label for British Indian Ocean Territory',
  },
  COUNTRY_BN: {
    id: 'countriesL10nMessages.BN',
    defaultMessage: 'Brunei Darussalam',
    description: '[Type: label][Vis: high] - country label for Brunei Darussalam',
  },
  COUNTRY_BG: {
    id: 'countriesL10nMessages.BG',
    defaultMessage: 'Bulgaria',
    description: '[Type: label][Vis: high] - country label for Bulgaria',
  },
  COUNTRY_BF: {
    id: 'countriesL10nMessages.BF',
    defaultMessage: 'Burkina Faso',
    description: '[Type: label][Vis: high] - country label for Burkina Faso',
  },
  COUNTRY_BI: {
    id: 'countriesL10nMessages.BI',
    defaultMessage: 'Burundi',
    description: '[Type: label][Vis: high] - country label for Burundi',
  },
  COUNTRY_KH: {
    id: 'countriesL10nMessages.KH',
    defaultMessage: 'Cambodia',
    description: '[Type: label][Vis: high] - country label for Cambodia',
  },
  COUNTRY_CM: {
    id: 'countriesL10nMessages.CM',
    defaultMessage: 'Cameroon',
    description: '[Type: label][Vis: high] - country label for Cameroon',
  },
  COUNTRY_CA: {
    id: 'countriesL10nMessages.CA',
    defaultMessage: 'Canada',
    description: '[Type: label][Vis: high] - country label for Canada',
  },
  COUNTRY_CV: {
    id: 'countriesL10nMessages.CV',
    defaultMessage: 'Cape Verde',
    description: '[Type: label][Vis: high] - country label for Cape Verde',
  },
  COUNTRY_KY: {
    id: 'countriesL10nMessages.KY',
    defaultMessage: 'Cayman Islands',
    description: '[Type: label][Vis: high] - country label for Cayman Islands',
  },
  COUNTRY_CF: {
    id: 'countriesL10nMessages.CF',
    defaultMessage: 'Central African Republic',
    description: '[Type: label][Vis: high] - country label for Central African Republic',
  },
  COUNTRY_TD: {
    id: 'countriesL10nMessages.TD',
    defaultMessage: 'Chad',
    description: '[Type: label][Vis: high] - country label for Chad',
  },
  COUNTRY_CL: {
    id: 'countriesL10nMessages.CL',
    defaultMessage: 'Chile',
    description: '[Type: label][Vis: high] - country label for Chile',
  },
  COUNTRY_CN: {
    id: 'countriesL10nMessages.CN',
    defaultMessage: 'China',
    description: '[Type: label][Vis: high] - country label for China',
  },
  COUNTRY_CX: {
    id: 'countriesL10nMessages.CX',
    defaultMessage: 'Christmas Island',
    description: '[Type: label][Vis: high] - country label for Christmas Island',
  },
  COUNTRY_CC: {
    id: 'countriesL10nMessages.CC',
    defaultMessage: 'Cocos (Keeling) Islands',
    description: '[Type: label][Vis: high] - country label for Cocos (Keeling) Islands',
  },
  COUNTRY_CO: {
    id: 'countriesL10nMessages.CO',
    defaultMessage: 'Colombia',
    description: '[Type: label][Vis: high] - country label for Colombia',
  },
  COUNTRY_KM: {
    id: 'countriesL10nMessages.KM',
    defaultMessage: 'Comoros',
    description: '[Type: label][Vis: high] - country label for Comoros',
  },
  COUNTRY_CG: {
    id: 'countriesL10nMessages.CG',
    defaultMessage: 'Congo',
    description: '[Type: label][Vis: high] - country label for Congo',
  },
  COUNTRY_CD: {
    id: 'countriesL10nMessages.CD',
    defaultMessage: 'Congo, The Democratic Republic of the',
    description: '[Type: label][Vis: high] - country label for Congo, The Democratic Republic of the',
  },
  COUNTRY_CK: {
    id: 'countriesL10nMessages.CK',
    defaultMessage: 'Cook Islands',
    description: '[Type: label][Vis: high] - country label for Cook Islands',
  },
  COUNTRY_CR: {
    id: 'countriesL10nMessages.CR',
    defaultMessage: 'Costa Rica',
    description: '[Type: label][Vis: high] - country label for Costa Rica',
  },
  COUNTRY_CI: {
    id: 'countriesL10nMessages.CI',
    defaultMessage: "Côte d'Ivoire",
    description: "[Type: label][Vis: high] - country label for Côte d'Ivoire",
  },
  COUNTRY_HR: {
    id: 'countriesL10nMessages.HR',
    defaultMessage: 'Croatia',
    description: '[Type: label][Vis: high] - country label for Croatia',
  },
  COUNTRY_CW: {
    id: 'countriesL10nMessages.CW',
    defaultMessage: 'Curaçao',
    description: '[Type: label][Vis: high] - country label for Curaçao',
  },
  COUNTRY_CY: {
    id: 'countriesL10nMessages.CY',
    defaultMessage: 'Cyprus',
    description: '[Type: label][Vis: high] - country label for Cyprus',
  },
  COUNTRY_CZ: {
    id: 'countriesL10nMessages.CZ',
    defaultMessage: 'Czech Republic',
    description: '[Type: label][Vis: high] - country label for Czech Republic',
  },
  COUNTRY_DK: {
    id: 'countriesL10nMessages.DK',
    defaultMessage: 'Denmark',
    description: '[Type: label][Vis: high] - country label for Denmark',
  },
  COUNTRY_DJ: {
    id: 'countriesL10nMessages.DJ',
    defaultMessage: 'Djibouti',
    description: '[Type: label][Vis: high] - country label for Djibouti',
  },
  COUNTRY_DM: {
    id: 'countriesL10nMessages.DM',
    defaultMessage: 'Dominica',
    description: '[Type: label][Vis: high] - country label for Dominica',
  },
  COUNTRY_DO: {
    id: 'countriesL10nMessages.DO',
    defaultMessage: 'Dominican Republic',
    description: '[Type: label][Vis: high] - country label for Dominican Republic',
  },
  COUNTRY_EC: {
    id: 'countriesL10nMessages.EC',
    defaultMessage: 'Ecuador',
    description: '[Type: label][Vis: high] - country label for Ecuador',
  },
  COUNTRY_EG: {
    id: 'countriesL10nMessages.EG',
    defaultMessage: 'Egypt',
    description: '[Type: label][Vis: high] - country label for Egypt',
  },
  COUNTRY_SV: {
    id: 'countriesL10nMessages.SV',
    defaultMessage: 'El Salvador',
    description: '[Type: label][Vis: high] - country label for El Salvador',
  },
  COUNTRY_GQ: {
    id: 'countriesL10nMessages.GQ',
    defaultMessage: 'Equatorial Guinea',
    description: '[Type: label][Vis: high] - country label for Equatorial Guinea',
  },
  COUNTRY_ER: {
    id: 'countriesL10nMessages.ER',
    defaultMessage: 'Eritrea',
    description: '[Type: label][Vis: high] - country label for Eritrea',
  },
  COUNTRY_EE: {
    id: 'countriesL10nMessages.EE',
    defaultMessage: 'Estonia',
    description: '[Type: label][Vis: high] - country label for Estonia',
  },
  COUNTRY_ET: {
    id: 'countriesL10nMessages.ET',
    defaultMessage: 'Ethiopia',
    description: '[Type: label][Vis: high] - country label for Ethiopia',
  },
  COUNTRY_FK: {
    id: 'countriesL10nMessages.FK',
    defaultMessage: 'Falkland Islands',
    description: '[Type: label][Vis: high] - country label for Falkland Islands',
  },
  COUNTRY_FO: {
    id: 'countriesL10nMessages.FO',
    defaultMessage: 'Faroe Islands',
    description: '[Type: label][Vis: high] - country label for Faroe Islands',
  },
  COUNTRY_FM: {
    id: 'countriesL10nMessages.FM',
    defaultMessage: 'Federated States of Micronesia',
    description: '[Type: label][Vis: high] - country label for Federated States of Micronesia',
  },
  COUNTRY_FJ: {
    id: 'countriesL10nMessages.FJ',
    defaultMessage: 'Fiji',
    description: '[Type: label][Vis: high] - country label for Fiji',
  },
  COUNTRY_FI: {
    id: 'countriesL10nMessages.FI',
    defaultMessage: 'Finland',
    description: '[Type: label][Vis: high] - country label for Finland',
  },
  COUNTRY_FR: {
    id: 'countriesL10nMessages.FR',
    defaultMessage: 'France',
    description: '[Type: label][Vis: high] - country label for France',
  },
  COUNTRY_GF: {
    id: 'countriesL10nMessages.GF',
    defaultMessage: 'French Guiana',
    description: '[Type: label][Vis: high] - country label for French Guiana',
  },
  COUNTRY_PF: {
    id: 'countriesL10nMessages.PF',
    defaultMessage: 'French Polynesia',
    description: '[Type: label][Vis: high] - country label for French Polynesia',
  },
  COUNTRY_GA: {
    id: 'countriesL10nMessages.GA',
    defaultMessage: 'Gabon',
    description: '[Type: label][Vis: high] - country label for Gabon',
  },
  COUNTRY_GM: {
    id: 'countriesL10nMessages.GM',
    defaultMessage: 'Gambia',
    description: '[Type: label][Vis: high] - country label for Gambia',
  },
  COUNTRY_GE: {
    id: 'countriesL10nMessages.GE',
    defaultMessage: 'Georgia',
    description: '[Type: label][Vis: high] - country label for Georgia',
  },
  COUNTRY_DE: {
    id: 'countriesL10nMessages.DE',
    defaultMessage: 'Germany',
    description: '[Type: label][Vis: high] - country label for Germany',
  },
  COUNTRY_GH: {
    id: 'countriesL10nMessages.GH',
    defaultMessage: 'Ghana',
    description: '[Type: label][Vis: high] - country label for Ghana',
  },
  COUNTRY_GI: {
    id: 'countriesL10nMessages.GI',
    defaultMessage: 'Gibraltar',
    description: '[Type: label][Vis: high] - country label for Gibraltar',
  },
  COUNTRY_GR: {
    id: 'countriesL10nMessages.GR',
    defaultMessage: 'Greece',
    description: '[Type: label][Vis: high] - country label for Greece',
  },
  COUNTRY_GL: {
    id: 'countriesL10nMessages.GL',
    defaultMessage: 'Greenland',
    description: '[Type: label][Vis: high] - country label for Greenland',
  },
  COUNTRY_GD: {
    id: 'countriesL10nMessages.GD',
    defaultMessage: 'Grenada',
    description: '[Type: label][Vis: high] - country label for Grenada',
  },
  COUNTRY_GP: {
    id: 'countriesL10nMessages.GP',
    defaultMessage: 'Guadeloupe',
    description: '[Type: label][Vis: high] - country label for Guadeloupe',
  },
  COUNTRY_GU: {
    id: 'countriesL10nMessages.GU',
    defaultMessage: 'Guam',
    description: '[Type: label][Vis: high] - country label for Guam',
  },
  COUNTRY_GT: {
    id: 'countriesL10nMessages.GT',
    defaultMessage: 'Guatemala',
    description: '[Type: label][Vis: high] - country label for Guatemala',
  },
  COUNTRY_GG: {
    id: 'countriesL10nMessages.GG',
    defaultMessage: 'Guernsey',
    description: '[Type: label][Vis: high] - country label for Guernsey',
  },
  COUNTRY_GN: {
    id: 'countriesL10nMessages.GN',
    defaultMessage: 'Guinea',
    description: '[Type: label][Vis: high] - country label for Guinea',
  },
  COUNTRY_GW: {
    id: 'countriesL10nMessages.GW',
    defaultMessage: 'Guinea-Bissau',
    description: '[Type: label][Vis: high] - country label for Guinea-Bissau',
  },
  COUNTRY_GY: {
    id: 'countriesL10nMessages.GY',
    defaultMessage: 'Guyana',
    description: '[Type: label][Vis: high] - country label for Guyana',
  },
  COUNTRY_HT: {
    id: 'countriesL10nMessages.HT',
    defaultMessage: 'Haiti',
    description: '[Type: label][Vis: high] - country label for Haiti',
  },
  COUNTRY_VA: {
    id: 'countriesL10nMessages.VA',
    defaultMessage: 'Holy See (Vatican City State)',
    description: '[Type: label][Vis: high] - country label for Holy See (Vatican City State)',
  },
  COUNTRY_HN: {
    id: 'countriesL10nMessages.HN',
    defaultMessage: 'Honduras',
    description: '[Type: label][Vis: high] - country label for Honduras',
  },
  COUNTRY_HK: {
    id: 'countriesL10nMessages.HK',
    defaultMessage: 'Hong Kong',
    description: '[Type: label][Vis: high] - country label for Hong Kong',
  },
  COUNTRY_HU: {
    id: 'countriesL10nMessages.HU',
    defaultMessage: 'Hungary',
    description: '[Type: label][Vis: high] - country label for Hungary',
  },
  COUNTRY_IS: {
    id: 'countriesL10nMessages.IS',
    defaultMessage: 'Iceland',
    description: '[Type: label][Vis: high] - country label for Iceland',
  },
  COUNTRY_IN: {
    id: 'countriesL10nMessages.IN',
    defaultMessage: 'India',
    description: '[Type: label][Vis: high] - country label for India',
  },
  COUNTRY_ID: {
    id: 'countriesL10nMessages.ID',
    defaultMessage: 'Indonesia',
    description: '[Type: label][Vis: high] - country label for Indonesia',
  },
  COUNTRY_IQ: {
    id: 'countriesL10nMessages.IQ',
    defaultMessage: 'Iraq',
    description: '[Type: label][Vis: high] - country label for Iraq',
  },
  COUNTRY_IE: {
    id: 'countriesL10nMessages.IE',
    defaultMessage: 'Ireland',
    description: '[Type: label][Vis: high] - country label for Ireland',
  },
  COUNTRY_IM: {
    id: 'countriesL10nMessages.IM',
    defaultMessage: 'Isle of Man',
    description: '[Type: label][Vis: high] - country label for Isle of Man',
  },
  COUNTRY_IL: {
    id: 'countriesL10nMessages.IL',
    defaultMessage: 'Israel',
    description: '[Type: label][Vis: high] - country label for Israel',
  },
  COUNTRY_IT: {
    id: 'countriesL10nMessages.IT',
    defaultMessage: 'Italy',
    description: '[Type: label][Vis: high] - country label for Italy',
  },
  COUNTRY_JM: {
    id: 'countriesL10nMessages.JM',
    defaultMessage: 'Jamaica',
    description: '[Type: label][Vis: high] - country label for Jamaica',
  },
  COUNTRY_JP: {
    id: 'countriesL10nMessages.JP',
    defaultMessage: 'Japan',
    description: '[Type: label][Vis: high] - country label for Japan',
  },
  COUNTRY_JE: {
    id: 'countriesL10nMessages.JE',
    defaultMessage: 'Jersey',
    description: '[Type: label][Vis: high] - country label for Jersey',
  },
  COUNTRY_JO: {
    id: 'countriesL10nMessages.JO',
    defaultMessage: 'Jordan',
    description: '[Type: label][Vis: high] - country label for Jordan',
  },
  COUNTRY_KZ: {
    id: 'countriesL10nMessages.KZ',
    defaultMessage: 'Kazakhstan',
    description: '[Type: label][Vis: high] - country label for Kazakhstan',
  },
  COUNTRY_KE: {
    id: 'countriesL10nMessages.KE',
    defaultMessage: 'Kenya',
    description: '[Type: label][Vis: high] - country label for Kenya',
  },
  COUNTRY_KI: {
    id: 'countriesL10nMessages.KI',
    defaultMessage: 'Kiribati',
    description: '[Type: label][Vis: high] - country label for Kiribati',
  },
  COUNTRY_XK: {
    id: 'countriesL10nMessages.XK',
    defaultMessage: 'Kosovo',
    description: '[Type: label][Vis: high] - country label for Kosovo',
  },
  COUNTRY_KW: {
    id: 'countriesL10nMessages.KW',
    defaultMessage: 'Kuwait',
    description: '[Type: label][Vis: high] - country label for Kuwait',
  },
  COUNTRY_KG: {
    id: 'countriesL10nMessages.KG',
    defaultMessage: 'Kyrgyzstan',
    description: '[Type: label][Vis: high] - country label for Kyrgyzstan',
  },
  COUNTRY_LA: {
    id: 'countriesL10nMessages.LA',
    defaultMessage: 'Laos',
    description: '[Type: label][Vis: high] - country label for Laos',
  },
  COUNTRY_LV: {
    id: 'countriesL10nMessages.LV',
    defaultMessage: 'Latvia',
    description: '[Type: label][Vis: high] - country label for Latvia',
  },
  COUNTRY_LB: {
    id: 'countriesL10nMessages.LB',
    defaultMessage: 'Lebanon',
    description: '[Type: label][Vis: high] - country label for Lebanon',
  },
  COUNTRY_LS: {
    id: 'countriesL10nMessages.LS',
    defaultMessage: 'Lesotho',
    description: '[Type: label][Vis: high] - country label for Lesotho',
  },
  COUNTRY_LR: {
    id: 'countriesL10nMessages.LR',
    defaultMessage: 'Liberia',
    description: '[Type: label][Vis: high] - country label for Liberia',
  },
  COUNTRY_LY: {
    id: 'countriesL10nMessages.LY',
    defaultMessage: 'Libya',
    description: '[Type: label][Vis: high] - country label for Libya',
  },
  COUNTRY_LI: {
    id: 'countriesL10nMessages.LI',
    defaultMessage: 'Liechtenstein',
    description: '[Type: label][Vis: high] - country label for Liechtenstein',
  },
  COUNTRY_LT: {
    id: 'countriesL10nMessages.LT',
    defaultMessage: 'Lithuania',
    description: '[Type: label][Vis: high] - country label for Lithuania',
  },
  COUNTRY_LU: {
    id: 'countriesL10nMessages.LU',
    defaultMessage: 'Luxembourg',
    description: '[Type: label][Vis: high] - country label for Luxembourg',
  },
  COUNTRY_MO: {
    id: 'countriesL10nMessages.MO',
    defaultMessage: 'Macao',
    description: '[Type: label][Vis: high] - country label for Macao',
  },
  COUNTRY_MK: {
    id: 'countriesL10nMessages.MK',
    defaultMessage: 'Macedonia',
    description: '[Type: label][Vis: high] - country label for Macedonia',
  },
  COUNTRY_MG: {
    id: 'countriesL10nMessages.MG',
    defaultMessage: 'Madagascar',
    description: '[Type: label][Vis: high] - country label for Madagascar',
  },
  COUNTRY_MW: {
    id: 'countriesL10nMessages.MW',
    defaultMessage: 'Malawi',
    description: '[Type: label][Vis: high] - country label for Malawi',
  },
  COUNTRY_MY: {
    id: 'countriesL10nMessages.MY',
    defaultMessage: 'Malaysia',
    description: '[Type: label][Vis: high] - country label for Malaysia',
  },
  COUNTRY_MV: {
    id: 'countriesL10nMessages.MV',
    defaultMessage: 'Maldives',
    description: '[Type: label][Vis: high] - country label for Maldives',
  },
  COUNTRY_ML: {
    id: 'countriesL10nMessages.ML',
    defaultMessage: 'Mali',
    description: '[Type: label][Vis: high] - country label for Mali',
  },
  COUNTRY_MT: {
    id: 'countriesL10nMessages.MT',
    defaultMessage: 'Malta',
    description: '[Type: label][Vis: high] - country label for Malta',
  },
  COUNTRY_MH: {
    id: 'countriesL10nMessages.MH',
    defaultMessage: 'Marshall Islands',
    description: '[Type: label][Vis: high] - country label for Marshall Islands',
  },
  COUNTRY_MQ: {
    id: 'countriesL10nMessages.MQ',
    defaultMessage: 'Martinique',
    description: '[Type: label][Vis: high] - country label for Martinique',
  },
  COUNTRY_MR: {
    id: 'countriesL10nMessages.MR',
    defaultMessage: 'Mauritania',
    description: '[Type: label][Vis: high] - country label for Mauritania',
  },
  COUNTRY_MU: {
    id: 'countriesL10nMessages.MU',
    defaultMessage: 'Mauritius',
    description: '[Type: label][Vis: high] - country label for Mauritius',
  },
  COUNTRY_YT: {
    id: 'countriesL10nMessages.YT',
    defaultMessage: 'Mayotte',
    description: '[Type: label][Vis: high] - country label for Mayotte',
  },
  COUNTRY_MX: {
    id: 'countriesL10nMessages.MX',
    defaultMessage: 'Mexico',
    description: '[Type: label][Vis: high] - country label for Mexico',
  },
  COUNTRY_MD: {
    id: 'countriesL10nMessages.MD',
    defaultMessage: 'Moldova',
    description: '[Type: label][Vis: high] - country label for Moldova',
  },
  COUNTRY_MC: {
    id: 'countriesL10nMessages.MC',
    defaultMessage: 'Monaco',
    description: '[Type: label][Vis: high] - country label for Monaco',
  },
  COUNTRY_MN: {
    id: 'countriesL10nMessages.MN',
    defaultMessage: 'Mongolia',
    description: '[Type: label][Vis: high] - country label for Mongolia',
  },
  COUNTRY_ME: {
    id: 'countriesL10nMessages.ME',
    defaultMessage: 'Montenegro',
    description: '[Type: label][Vis: high] - country label for Montenegro',
  },
  COUNTRY_MS: {
    id: 'countriesL10nMessages.MS',
    defaultMessage: 'Montserrat',
    description: '[Type: label][Vis: high] - country label for Montserrat',
  },
  COUNTRY_MA: {
    id: 'countriesL10nMessages.MA',
    defaultMessage: 'Morocco',
    description: '[Type: label][Vis: high] - country label for Morocco',
  },
  COUNTRY_MZ: {
    id: 'countriesL10nMessages.MZ',
    defaultMessage: 'Mozambique',
    description: '[Type: label][Vis: high] - country label for Mozambique',
  },
  COUNTRY_MM: {
    id: 'countriesL10nMessages.MM',
    defaultMessage: 'Myanmar',
    description: '[Type: label][Vis: high] - country label for Myanmar',
  },
  COUNTRY_NA: {
    id: 'countriesL10nMessages.NA',
    defaultMessage: 'Namibia',
    description: '[Type: label][Vis: high] - country label for Namibia',
  },
  COUNTRY_NR: {
    id: 'countriesL10nMessages.NR',
    defaultMessage: 'Nauru',
    description: '[Type: label][Vis: high] - country label for Nauru',
  },
  COUNTRY_NP: {
    id: 'countriesL10nMessages.NP',
    defaultMessage: 'Nepal',
    description: '[Type: label][Vis: high] - country label for Nepal',
  },
  COUNTRY_NL: {
    id: 'countriesL10nMessages.NL',
    defaultMessage: 'Netherlands',
    description: '[Type: label][Vis: high] - country label for Netherlands',
  },
  COUNTRY_NC: {
    id: 'countriesL10nMessages.NC',
    defaultMessage: 'New Caledonia',
    description: '[Type: label][Vis: high] - country label for New Caledonia',
  },
  COUNTRY_NZ: {
    id: 'countriesL10nMessages.NZ',
    defaultMessage: 'New Zealand',
    description: '[Type: label][Vis: high] - country label for New Zealand',
  },
  COUNTRY_NI: {
    id: 'countriesL10nMessages.NI',
    defaultMessage: 'Nicaragua',
    description: '[Type: label][Vis: high] - country label for Nicaragua',
  },
  COUNTRY_NE: {
    id: 'countriesL10nMessages.NE',
    defaultMessage: 'Niger',
    description: '[Type: label][Vis: high] - country label for Niger',
  },
  COUNTRY_NG: {
    id: 'countriesL10nMessages.NG',
    defaultMessage: 'Nigeria',
    description: '[Type: label][Vis: high] - country label for Nigeria',
  },
  COUNTRY_NU: {
    id: 'countriesL10nMessages.NU',
    defaultMessage: 'Niue',
    description: '[Type: label][Vis: high] - country label for Niue',
  },
  COUNTRY_NF: {
    id: 'countriesL10nMessages.NF',
    defaultMessage: 'Norfolk Island',
    description: '[Type: label][Vis: high] - country label for Norfolk Island',
  },
  COUNTRY_MP: {
    id: 'countriesL10nMessages.MP',
    defaultMessage: 'Northern Mariana Island',
    description: '[Type: label][Vis: high] - country label for Northern Mariana Island',
  },
  COUNTRY_NO: {
    id: 'countriesL10nMessages.NO',
    defaultMessage: 'Norway',
    description: '[Type: label][Vis: high] - country label for Norway',
  },
  COUNTRY_OM: {
    id: 'countriesL10nMessages.OM',
    defaultMessage: 'Oman',
    description: '[Type: label][Vis: high] - country label for Oman',
  },
  COUNTRY_PK: {
    id: 'countriesL10nMessages.PK',
    defaultMessage: 'Pakistan',
    description: '[Type: label][Vis: high] - country label for Pakistan',
  },
  COUNTRY_PW: {
    id: 'countriesL10nMessages.PW',
    defaultMessage: 'Palau',
    description: '[Type: label][Vis: high] - country label for Palau',
  },
  COUNTRY_PS: {
    id: 'countriesL10nMessages.PS',
    defaultMessage: 'Palestine',
    description: '[Type: label][Vis: high] - country label for Palestine, State of',
  },
  COUNTRY_PA: {
    id: 'countriesL10nMessages.PA',
    defaultMessage: 'Panama',
    description: '[Type: label][Vis: high] - country label for Panama',
  },
  COUNTRY_PG: {
    id: 'countriesL10nMessages.PG',
    defaultMessage: 'Papua New Guinea',
    description: '[Type: label][Vis: high] - country label for Papua New Guinea',
  },
  COUNTRY_PY: {
    id: 'countriesL10nMessages.PY',
    defaultMessage: 'Paraguay',
    description: '[Type: label][Vis: high] - country label for Paraguay',
  },
  COUNTRY_PE: {
    id: 'countriesL10nMessages.PE',
    defaultMessage: 'Peru',
    description: '[Type: label][Vis: high] - country label for Peru',
  },
  COUNTRY_PH: {
    id: 'countriesL10nMessages.PH',
    defaultMessage: 'Philippines',
    description: '[Type: label][Vis: high] - country label for Philippines',
  },
  COUNTRY_PL: {
    id: 'countriesL10nMessages.PL',
    defaultMessage: 'Poland',
    description: '[Type: label][Vis: high] - country label for Poland',
  },
  COUNTRY_PT: {
    id: 'countriesL10nMessages.PT',
    defaultMessage: 'Portugal',
    description: '[Type: label][Vis: high] - country label for Portugal',
  },
  COUNTRY_PR: {
    id: 'countriesL10nMessages.PR',
    defaultMessage: 'Puerto Rico',
    description: '[Type: label][Vis: high] - country label for Puerto Rico',
  },
  COUNTRY_QA: {
    id: 'countriesL10nMessages.QA',
    defaultMessage: 'Qatar',
    description: '[Type: label][Vis: high] - country label for Qatar',
  },
  COUNTRY_RE: {
    id: 'countriesL10nMessages.RE',
    defaultMessage: 'Réunion',
    description: '[Type: label][Vis: high] - country label for Réunion',
  },
  COUNTRY_RO: {
    id: 'countriesL10nMessages.RO',
    defaultMessage: 'Romania',
    description: '[Type: label][Vis: high] - country label for Romania',
  },
  COUNTRY_RW: {
    id: 'countriesL10nMessages.RW',
    defaultMessage: 'Rwanda',
    description: '[Type: label][Vis: high] - country label for Rwanda',
  },
  COUNTRY_BL: {
    id: 'countriesL10nMessages.BL',
    defaultMessage: 'Saint Barthélemy',
    description: '[Type: label][Vis: high] - country label for Saint Barthélemy',
  },
  COUNTRY_SH: {
    id: 'countriesL10nMessages.SH',
    defaultMessage: 'Saint Helena',
    description: '[Type: label][Vis: high] - country label for Saint Helena',
  },
  COUNTRY_KN: {
    id: 'countriesL10nMessages.KN',
    defaultMessage: 'Saint Kitts and Nevis',
    description: '[Type: label][Vis: high] - country label for Saint Kitts and Nevis',
  },
  COUNTRY_LC: {
    id: 'countriesL10nMessages.LC',
    defaultMessage: 'Saint Lucia',
    description: '[Type: label][Vis: high] - country label for Saint Lucia',
  },
  COUNTRY_MF: {
    id: 'countriesL10nMessages.MF',
    defaultMessage: 'Saint Martin (French part)',
    description: '[Type: label][Vis: high] - country label for Saint Martin (French part)',
  },
  COUNTRY_PM: {
    id: 'countriesL10nMessages.PM',
    defaultMessage: 'Saint Pierre and Miquelon',
    description: '[Type: label][Vis: high] - country label for Saint Pierre and Miquelon',
  },
  COUNTRY_VC: {
    id: 'countriesL10nMessages.VC',
    defaultMessage: 'Saint Vincent and the Grenadines',
    description: '[Type: label][Vis: high] - country label for Saint Vincent and the Grenadines',
  },
  COUNTRY_WS: {
    id: 'countriesL10nMessages.WS',
    defaultMessage: 'Samoa',
    description: '[Type: label][Vis: high] - country label for Samoa',
  },
  COUNTRY_SM: {
    id: 'countriesL10nMessages.SM',
    defaultMessage: 'San Marino',
    description: '[Type: label][Vis: high] - country label for San Marino',
  },
  COUNTRY_ST: {
    id: 'countriesL10nMessages.ST',
    defaultMessage: 'Sao Tome and Principe',
    description: '[Type: label][Vis: high] - country label for Sao Tome and Principe',
  },
  COUNTRY_SA: {
    id: 'countriesL10nMessages.SA',
    defaultMessage: 'Saudi Arabia',
    description: '[Type: label][Vis: high] - country label for Saudi Arabia',
  },
  COUNTRY_SN: {
    id: 'countriesL10nMessages.SN',
    defaultMessage: 'Senegal',
    description: '[Type: label][Vis: high] - country label for Senegal',
  },
  COUNTRY_RS: {
    id: 'countriesL10nMessages.RS',
    defaultMessage: 'Serbia',
    description: '[Type: label][Vis: high] - country label for Serbia',
  },
  COUNTRY_SC: {
    id: 'countriesL10nMessages.SC',
    defaultMessage: 'Seychelles',
    description: '[Type: label][Vis: high] - country label for Seychelles',
  },
  COUNTRY_SL: {
    id: 'countriesL10nMessages.SL',
    defaultMessage: 'Sierra Leone',
    description: '[Type: label][Vis: high] - country label for Sierra Leone',
  },
  COUNTRY_SG: {
    id: 'countriesL10nMessages.SG',
    defaultMessage: 'Singapore',
    description: '[Type: label][Vis: high] - country label for Singapore',
  },
  COUNTRY_SX: {
    id: 'countriesL10nMessages.SX',
    defaultMessage: 'Sint Maarten',
    description: '[Type: label][Vis: high] - country label for Sint Maarten (Dutch part)',
  },
  COUNTRY_SK: {
    id: 'countriesL10nMessages.SK',
    defaultMessage: 'Slovakia',
    description: '[Type: label][Vis: high] - country label for Slovakia',
  },
  COUNTRY_SI: {
    id: 'countriesL10nMessages.SI',
    defaultMessage: 'Slovenia',
    description: '[Type: label][Vis: high] - country label for Slovenia',
  },
  COUNTRY_SB: {
    id: 'countriesL10nMessages.SB',
    defaultMessage: 'Solomon Islands',
    description: '[Type: label][Vis: high] - country label for Solomon Islands',
  },
  COUNTRY_SO: {
    id: 'countriesL10nMessages.SO',
    defaultMessage: 'Somalia',
    description: '[Type: label][Vis: high] - country label for Somalia',
  },
  COUNTRY_ZA: {
    id: 'countriesL10nMessages.ZA',
    defaultMessage: 'South Africa',
    description: '[Type: label][Vis: high] - country label for South Africa',
  },
  COUNTRY_KR: {
    id: 'countriesL10nMessages.KR',
    defaultMessage: 'South Korea',
    description: '[Type: label][Vis: high] - country label for South Korea',
  },
  COUNTRY_SS: {
    id: 'countriesL10nMessages.SS',
    defaultMessage: 'South Sudan',
    description: '[Type: label][Vis: high] - country label for South Sudan',
  },
  COUNTRY_ES: {
    id: 'countriesL10nMessages.ES',
    defaultMessage: 'Spain',
    description: '[Type: label][Vis: high] - country label for Spain',
  },
  COUNTRY_LK: {
    id: 'countriesL10nMessages.LK',
    defaultMessage: 'Sri Lanka',
    description: '[Type: label][Vis: high] - country label for Sri Lanka',
  },
  COUNTRY_SR: {
    id: 'countriesL10nMessages.SR',
    defaultMessage: 'Suriname',
    description: '[Type: label][Vis: high] - country label for Suriname',
  },
  COUNTRY_SJ: {
    id: 'countriesL10nMessages.SJ',
    defaultMessage: 'Svalbard and Jan Mayen',
    description: '[Type: label][Vis: high] - country label for Svalbard and Jan Mayen',
  },
  COUNTRY_SZ: {
    id: 'countriesL10nMessages.SZ',
    defaultMessage: 'Swaziland',
    description: '[Type: label][Vis: high] - country label for Swaziland',
  },
  COUNTRY_SE: {
    id: 'countriesL10nMessages.SE',
    defaultMessage: 'Sweden',
    description: '[Type: label][Vis: high] - country label for Sweden',
  },
  COUNTRY_CH: {
    id: 'countriesL10nMessages.CH',
    defaultMessage: 'Switzerland',
    description: '[Type: label][Vis: high] - country label for Switzerland',
  },
  COUNTRY_TW: {
    id: 'countriesL10nMessages.TW',
    defaultMessage: 'Taiwan',
    description: '[Type: label][Vis: high] - country label for Taiwan',
  },
  COUNTRY_TJ: {
    id: 'countriesL10nMessages.TJ',
    defaultMessage: 'Tajikistan',
    description: '[Type: label][Vis: high] - country label for Tajikistan',
  },
  COUNTRY_TZ: {
    id: 'countriesL10nMessages.TZ',
    defaultMessage: 'Tanzania',
    description: '[Type: label][Vis: high] - country label for Tanzania',
  },
  COUNTRY_TH: {
    id: 'countriesL10nMessages.TH',
    defaultMessage: 'Thailand',
    description: '[Type: label][Vis: high] - country label for Thailand',
  },
  COUNTRY_TL: {
    id: 'countriesL10nMessages.TL',
    defaultMessage: 'Timor-Leste',
    description: '[Type: label][Vis: high] - country label for Timor-Leste',
  },
  COUNTRY_TG: {
    id: 'countriesL10nMessages.TG',
    defaultMessage: 'Togo',
    description: '[Type: label][Vis: high] - country label for Togo',
  },
  COUNTRY_TK: {
    id: 'countriesL10nMessages.TK',
    defaultMessage: 'Tokelau',
    description: '[Type: label][Vis: high] - country label for Tokelau',
  },
  COUNTRY_TO: {
    id: 'countriesL10nMessages.TO',
    defaultMessage: 'Tonga',
    description: '[Type: label][Vis: high] - country label for Tonga',
  },
  COUNTRY_TT: {
    id: 'countriesL10nMessages.TT',
    defaultMessage: 'Trinidad and Tobago',
    description: '[Type: label][Vis: high] - country label for Trinidad and Tobago',
  },
  COUNTRY_TA: {
    id: 'countriesL10nMessages.TA',
    defaultMessage: 'Tristan da Cunha',
    description: '[Type: label][Vis: high] - country label for Tristan da Cunha',
  },
  COUNTRY_TN: {
    id: 'countriesL10nMessages.TN',
    defaultMessage: 'Tunisia',
    description: '[Type: label][Vis: high] - country label for Tunisia',
  },
  COUNTRY_TR: {
    id: 'countriesL10nMessages.TR',
    defaultMessage: 'Turkey',
    description: '[Type: label][Vis: high] - country label for Turkey',
  },
  COUNTRY_TM: {
    id: 'countriesL10nMessages.TM',
    defaultMessage: 'Turkmenistan',
    description: '[Type: label][Vis: high] - country label for Turkmenistan',
  },
  COUNTRY_TC: {
    id: 'countriesL10nMessages.TC',
    defaultMessage: 'Turks and Caicos Islands',
    description: '[Type: label][Vis: high] - country label for Turks and Caicos Islands',
  },
  COUNTRY_TV: {
    id: 'countriesL10nMessages.TV',
    defaultMessage: 'Tuvalu',
    description: '[Type: label][Vis: high] - country label for Tuvalu',
  },
  COUNTRY_UG: {
    id: 'countriesL10nMessages.UG',
    defaultMessage: 'Uganda',
    description: '[Type: label][Vis: high] - country label for Uganda',
  },
  COUNTRY_UA: {
    id: 'countriesL10nMessages.UA',
    defaultMessage: 'Ukraine',
    description: '[Type: label][Vis: high] - country label for Ukraine',
  },
  COUNTRY_AE: {
    id: 'countriesL10nMessages.AE',
    defaultMessage: 'United Arab Emirates',
    description: '[Type: label][Vis: high] - country label for United Arab Emirates',
  },
  COUNTRY_GB: {
    id: 'countriesL10nMessages.GB',
    defaultMessage: 'United Kingdom',
    description: '[Type: label][Vis: high] - country label for United Kingdom',
  },
  COUNTRY_US: {
    id: 'countriesL10nMessages.US',
    defaultMessage: 'United States',
    description: '[Type: label][Vis: high] - country label for United States',
  },
  COUNTRY_UY: {
    id: 'countriesL10nMessages.UY',
    defaultMessage: 'Uruguay',
    description: '[Type: label][Vis: high] - country label for Uruguay',
  },
  COUNTRY_UZ: {
    id: 'countriesL10nMessages.UZ',
    defaultMessage: 'Uzbekistan',
    description: '[Type: label][Vis: high] - country label for Uzbekistan',
  },
  COUNTRY_VU: {
    id: 'countriesL10nMessages.VU',
    defaultMessage: 'Vanuatu',
    description: '[Type: label][Vis: high] - country label for Vanuatu',
  },
  COUNTRY_VE: {
    id: 'countriesL10nMessages.VE',
    defaultMessage: 'Venezuela',
    description: '[Type: label][Vis: high] - country label for Venezuela',
  },
  COUNTRY_VN: {
    id: 'countriesL10nMessages.VN',
    defaultMessage: 'Vietnam',
    description: '[Type: label][Vis: high] - country label for Vietnam',
  },
  COUNTRY_VI: {
    id: 'countriesL10nMessages.VI',
    defaultMessage: 'Virgin Islands, U.S.',
    description: '[Type: label][Vis: high] - country label for Virgin Islands, U.S.',
  },
  COUNTRY_VG: {
    id: 'countriesL10nMessages.VG',
    defaultMessage: 'Virgin Islands, British',
    description: '[Type: label][Vis: high] - country label for Virgin Islands, British',
  },
  COUNTRY_WF: {
    id: 'countriesL10nMessages.WF',
    defaultMessage: 'Wallis and Futuna',
    description: '[Type: label][Vis: high] - country label for Wallis and Futuna',
  },
  COUNTRY_EH: {
    id: 'countriesL10nMessages.EH',
    defaultMessage: 'Western Sahara',
    description: '[Type: label][Vis: high] - country label for Western Sahara',
  },
  COUNTRY_YE: {
    id: 'countriesL10nMessages.YE',
    defaultMessage: 'Yemen',
    description: '[Type: label][Vis: high] - country label for Yemen',
  },
  COUNTRY_ZM: {
    id: 'countriesL10nMessages.ZM',
    defaultMessage: 'Zambia',
    description: '[Type: label][Vis: high] - country label for Zambia',
  },
  COUNTRY_ZW: {
    id: 'countriesL10nMessages.ZW',
    defaultMessage: 'Zimbabwe',
    description: '[Type: label][Vis: high] - country label for Zimbabwe',
  },
  CHECKBOX_LABEL: {
    id: 'ToggleableEmailField.checkboxLabel',
    defaultMessage: 'Send me a copy of my responses via email',
    description: '[Type: label][Vis: high] - label for checkbox input',
  },
  EMAIL_LABEL: {
    id: 'ToggleableEmailField.emailLabel',
    defaultMessage: 'Enter your email address',
    description: '[Type: label][Vis: high] - label for text input',
  },
});

export default COPY;
